.ControlPanel {
  padding: 27px 24px;

  .header__titles {
    border: none;
  }
  .block__header {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 16px;
    color: #125d98;
  }
  .narrowtext {
    width: 9%;
    padding: 5px 0px;
  }

  .arrow_next {
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 11px;
  }
  .arrow_prev {
    padding-bottom: 20px;
    display: flex;
    align-items: center;
    gap: 11px;
  }

  .block1{
    display: grid;
    row-gap: 52px;
  }

  .modal_blocks {
    display: flex;
    justify-content: space-between;
  }

  .modal_input {

    label {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #474665;
    }

    input {
      outline: none;
    width: 222px;
      height: 2px;
      margin-top: 12px;
      font-size: 16px;
      font-weight: 400;
      border: none;
      border-bottom: 1px solid #474665;
    }
  }

  .reservedFull{
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background: rgba(0, 182, 51, 0.4);    
    height: 100%;
  }
}


.footer_button_end{
  button{
    width: 120px;
    height: 36px;
  }
}
