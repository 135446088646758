.AjiHeads {
  padding: 0px 28px;
}
.Subagents{
  padding: 0px 28px;
}
  .user_list {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 28px 0px;

    .block__to-media__users-img{
      display: flex;
      gap: 20px;
    }

      
  .about__phones-user{
    display: flex;
    justify-content: center;
    gap: 20px;
  }

  .about__phones-user.active{
    display: flex;
    gap: 100px;
    @media (max-width: 560px) {
      flex-wrap: wrap;
      gap: 50px;
    }

    @media (max-width: 500px) {
      gap: 20px;
    }

    
  }
    

    .user_info_icon {
      img {
        border-radius: 100%;
        width: 80px;
        height: 80px;
      }
    }

    .user_info {
        
      .user_info_title {
        font-family: "Lato", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        color: #125d98;

      }

      a.reset__pasword{
        font-size: 14px;
        text-decoration:underline;
        margin-top: 10px;
        color: #0077D5;
      }

      .user_info_text {
        margin-top: 10px;
        font-family: "Lato", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #112f53;
      }

      .user_info_input {
        margin-top: 10px;
        font-family: "Lato", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #112f53;

        input{
          padding: 0;
          width: 100%;
          font-size: 14px;
          text-decoration:underline;
          color: #0077D5;
          border: none;
          cursor: pointer;
        }
      }
    }

  

    .user_info_btns {
      display: flex;
      align-items: flex-start;
      gap: 20px;

      img{
        cursor: pointer;
      }

      .user_info_btn_2{
        color: #125D98;
        background: #ffffff;
        border:1px solid #125D98;
      }
    }
  }

