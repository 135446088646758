.second__main-wrapper{
    padding-left: 100px;
}

.max__container{
  max-width: 1920px;
  margin: 0 auto;
}

.main{
    display: flex;
    align-items: center;
    margin-top: 40px;
}

@media(max-width:1300px){
   .main{
    display: block;
    justify-content: center;
   }

   .abous__us-image__background{
    display: flex;
    justify-content: center;

   }
}


@media(max-width:1050px){
    img.image-about__us{
        height: 400px;
        width: 500px;
    }
}

@media(max-width:900px){
  .second__main-wrapper{
    display: block;
  }

  img.image-about__us{
    display: flex;
    justify-content: center;
  }
}


@media(max-width:760px){
  .second__main-wrapper{
    padding-left: 0;
  }
}


@media(max-width:500px){
    img.image-about__us{
        height: 300px;
        width: 400px;
    }
}

@media(max-width:500px){
    img.image-about__us{
        height: 250px;
        width: 350px;
    }
}

@media(max-width:370px){
    img.image-about__us{
        height: 200px;
        width: 300px;
    }
}
