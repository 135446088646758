.answer-wrapper{
    max-width: 1180px;
    margin: 112px auto;
}


.answer-inside__first-box{
    padding: 52px 60px;
    border-bottom: 3px solid rgba(205, 214, 218, 0.25);;
}


.dolgo__reshal{
    display: flex;
    gap: 37px;
}


.trip__and-icon{
    display: flex;
    justify-content: space-between;
}


.trip{
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 28px;
    line-height: 39px;
    color: #125D98;
    cursor: pointer;
}
.add__icon{
    padding: 15px 16px 15px 17px;
    height: 18px;
    background: #F3F5F6;
    border-radius: 50%;
    color: black;
    cursor: pointer;
}

.add__icon.active{
    padding: 15px 16px 15px 17px;
    height: 18px;
    background: #E1F4FF;
    border-radius: 50%;
    color: black;
    cursor: pointer;
}


.h1-class__title{
    font-style: normal;
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    letter-spacing: 0.25px;
    color: rgba(60, 60, 67, 0.5);
}

@keyframes slideInLeft{
    from{
        transform: scale(0.96);
    }

    to{
        transform: scale(1);
    }
}

.inside-bottom__box{
    animation: slideInLeft 0.6s ease-in-out both ;
    margin-top: 24px;
    margin-left: 54px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    color: #112F53;

    /* transform: translateY(-100px); */
}

@media (max-width:825px){
    .answer-inside__first-box{
        display: block;
    }

    .add__icon{
    margin-top: 20px;

    }

    .top__box2{
        gap: 0;
        justify-content: space-between;
    }

    .dolgo__reshal{
        display: block;
    }

    .inside-bottom__box{
        margin-left: 0;
    }
    
    .trip{
        font-size: 24px;
    }

    .inside-bottom__box{
        font-size: 14px;
    }

}

@media (max-width:670px){
.trip{
    font-size: 20px;
}
  
}

@media (max-width:600px){
    .trip{
        font-size: 20px;
    }
      
    }

@media (max-width:530px){
    .answer-inside__first-box{
        padding:  60px 0;
    }
}

@media (max-width:400px){
    .trip{
        font-size: 18px;
    }
      
    }


