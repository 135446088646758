.AddTour {
  padding: 27px 24px;

  .package {
    &__title {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #112f53;
    }

    &-add {
      margin-left: 8px;
      cursor: pointer;
      margin-top: 32px;
      width: 75%;
      height: 368px;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      border: 2px dashed #125d98;
      border-radius: 12px;

      &__block {
        &__text {
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: 700;
          font-size: 20px;
          line-height: 24px;
          color: #125d98;
        }

        img {
          padding-top: 15px;
        }
      }

      &:hover {
        background: rgba(18, 93, 152, 0.06);
      }
    }
  }

  .added__items {
    padding-left: 8px;
  }
}

.addtour__item {
  margin-top: 28px;
  border: 2px solid #125d98;
  border-radius: 12px;
  width: 75.5%;

  &-block {
    padding: 32px;

    .addtour__Item__raises {
      display: flex;
      align-items: center;
      padding-top: 24px;
      flex-wrap: wrap;
      gap: 44px;
      padding-bottom: 5px;

      .line-price {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 17px;
        width: 338px;
        height: 53px;
        background: #f7fafc;
        border-radius: 6px;

        &__text1 {
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #112f53;
        }

        &__text2 {
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;

          color: #125d98;
        }
      }

      .line__title {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #112f53;
      }

      .line__items {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        gap: 20px;
        column-gap: 60px;

        &__item {
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 10px;
        }

        &__text {
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;

          color: #112f53;
        }
        &__textblue {
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          color: #125d98;
        }
      }
    }
  }

  &__title {
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 140%;
    color: #112f53;
  }
}
