.flights-wrapper {
    max-width: 1097px;
    margin: 76px auto;
}



.left_the-icon__ajy {
    display: flex;
    gap: 20px;
    margin-bottom: 40px;
}

.left_the-icon__ajy img{
    border-radius: 50%;
}

.title__hot__present{
    margin-top: 50px;
    display: flex;
    justify-content: center;
    font-size: 30px;
    color: #125D98;
}


.hot__title{
    font-size: 22px;
    color: #125D98;
    margin-bottom: 20px;
    display: flex;
    gap: 10px;
}


.flights-box__inside.active{
    display: flex;
    justify-content: space-evenly;
}

.category__icon-svg img {
    width: 40px;
    height: 40px;
}

.flights-tours_hide {
    display: none;
    gap: 5px;
}

.flights-tours_hide b {
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #112F53;
    border-bottom: 1px solid #112F53;
}



.modalBook {
    height: 100vh;
    width: 100vw;
    background-color: rgba(0, 0, 0, .4);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0;
    pointer-events: none;
    transition: 0.5s;
    z-index: 1000;
}

.modalBook.active {
    opacity: 1;
    pointer-events: all;
}

.modalBook__content {
    padding: 28px 32px 26px 32px;
    border-radius: 12px;
    background-color: white;
    transform: scale(0.5);
    transition: 0.2s all;
    width: 643px;

}

.thingBook {
    opacity: 0.6;
}

.modalBook__content input {
    width: 85%;
}

.modalBook__content.active {
    transform: scale(1);

}

.fill__details {
    display: flex;
    justify-content: center;
    font-style: normal;
    font-weight: 300;
    font-size: 24px;
    line-height: 165%;
    color: #000000;
    margin-bottom: 20px;
}

.modal-icon__user {
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
}

.to-book__call {
    margin-bottom: 32px;
}

.call__number-book {
    margin-bottom: 32px;

}

.call__message-book {
    margin-bottom: 36px;

}

.friendly__buttons {
    display: flex;
    justify-content: end;
    gap: 20px;
}


.flights-wrapper__text {
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    color: #000000;
}

.date-media {
    display: flex;
    gap: 30px;
}

.flights__box {
    margin-top: 40px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    padding: 28px 28px 0px 28px;
}

.flights__box.hot{
    margin-top: 40px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
    border: 1px solid #ffd700;
    padding: 28px 28px 0px 28px;
}

.flights-box__inside {
    display: flex;
    gap: 40px;
}

.top-display__flexed {
    display: flex;
    justify-content: space-between;
}

.flights__box2 {
    margin-top: 40px;
    background: #FFFFFF;
    border-radius: 12px;
    padding: 28px 56px 0px 28px;
}

.flights__box:not(:last-child) {
    margin-bottom: 28px;
}


.about-flight__time {
    gap: 20px;
    flex-wrap: wrap;
    display: flex;
    justify-content: space-between;
}

.flights-tours {
    display: flex;
    gap: 5px;
    align-items: center;
}


.flights-tours b {
    cursor: pointer;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #112F53;
    border-bottom: 1px solid #112F53;
}

.without__div {
    display: flex;
    align-items: center;
    gap: 5px;
}


.right__side {
    display: flex;
    justify-content: end;
    margin-top: 20px;
    margin-bottom: 28px;
}

.indise__right-side {
    justify-content: space-between;
}


.flight-inspection {
    display: flex;
    gap: 30px;
    align-items: center;
}

.flights-date {
    display: flex;
    gap: 17px;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #112F53;
}

.flight-city {
    display: flex;
    align-items: center;
    gap: 11px;
}

.city {
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 19px;
    color: black;
}

.date {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: black;
}

.flight-plane {
    display: flex;
    gap: 10px;
    align-items: center;
}

.flight-plane__town {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;

    color: #112F53;

}

.flights-hotel {
    display: flex;
    align-items: center;
    gap: 13px;
}

.first-flight {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 6px;
    margin-top: 20px;
}

.osh_city {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;

    color: #474665;
}


.hotel-media {
    display: flex;
    align-items: center;
    color: #125D98;
    column-gap: 13px;
}

.two-hotelmap {
    display: flex;
    align-items: center;
    color: #112F53;
    gap: 10px;
}

.flights-hotel {
    margin-left: 20px;
}

.flight-times__right {
    display: flex;
    gap: 20px;
    align-items: center;
}


.standart-flight {
    text-align: center;
}


.top_first-child img {
    border-radius: 50%;
}

.standart {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 140%;
    /* or 22px */
    display: flex;
    align-items: center;

    color: #112F53;
}

.flight-price {
    display: flex;
    gap: 10px;
    align-items: center;
    color: #125D98;
}

.people_icon {
    display: flex;
    align-items: center;
    gap: 6px;
}



.many__place {
    margin-left: 13px;
}

.people_icon b {
    font-style: normal;
    font-weight: 400;
    font-size: 20.6742px;
    line-height: 25px;
    display: flex;
    align-items: center;

    color: #125D98;
}


.book {
    margin-top: 21px;
    padding: 10px 44px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #ffff;
    background: #125D98;
    border-radius: 4px;
    border: 1px solid #125D98;
    transition: 0.4s ease-in-out;

}

.book:hover {
    transform: scale(1.05);
    background-color: #125D98;
    color: white;
}

.top-flights {
    display: flex;
    justify-content: space-between;
}

.top_first-child {
    display: flex;
    align-items: flex-start;
    gap: 26px;
}

.flights-more {
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
    color: #125D98;

}


.hidden-btn__more {
    margin-top: 20px;
    display: none;

}


.to-more__hide {
    background-color: #125D98;
    border-radius: 4px;
    padding: 11px 106px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 140%;
    color: #ffff;
    cursor: pointer;
    border: 1px solid #125D98;

}

.to-more__hide:hover {
    background-color: #125D98;
    color: white;
}

.flight__footer{
    display: flex;
    justify-content: end;
}








@media (max-width: 1138px) {

    .flights-hotel img {
        width: 20px;
        height: 28px;
    }
}

@media (max-width:1045px) {

    .flights-tours {
        display: block;
        gap: 0;
    }

    .flights-tours_hide p {
        margin-bottom: 5px;
    }

    .flights-tours p {
        margin-bottom: 5px;
    }
}


@media(max-width:980px) {
    .right__side {
        justify-content: center;
    }

    .flight-inspection {
        display: block;
        justify-content: center;
    }
}



@media (max-width:830px) {
    .top-display {
        align-items: center;
        justify-content: center;
    }

    .right__side {
        justify-content: flex-end;
    }

    .hidden-btn__more {
        display: inline;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 20px;
    }

    .flights__box {
        padding-bottom: 17px;
    }

    .flights__box.hot {
        padding-bottom: 17px;
    }

}



@media (max-width:750px) {
    .modalBook__content {
        width: 500px;
    }

}


@media (max-width:700px) {
    .flights-hotel img {
        width: 15px;
        height: 18px;
    }

    .flight-times__right {
        flex-wrap: wrap;
    }

    .people_icon div {
        font-size: 15px;
    }

    .date-media {
        flex-wrap: wrap;
        column-gap: 15px;
        row-gap: 8px;
    }




}

@media (max-width:680px) {
    .top-display {
        justify-content: flex-start;
    }
}


@media(max-width:655px) {

    .flights-box__inside {
        display: block;
    }

    .flights-box__inside.active{
        display: block;
    }

    .top-display__flexed {
        display: none;
    }

    .flights-tours_hide{
        display: inline;
    }

    .flights-tours_hide:has(div) {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }

    

}

@media(max-width:600px) {



    .modal-icon__user svg {
        width: 50px;
    }

    .modalBook__content {
        width: 400px;
    }

}

@media (max-width:560px) {
    .fliht-ajy{
        width: 100%;
        max-width: 80px;
        height: 80px;
    }

    .city{
        font-size: 14px;
    }

    .flight_line{
        width: 33px;
    }

    .category__icon-svg img {
        width: 30px;
        height: 30px;
    }

    .flights-tours_hide b{
        font-size: 18px;
    }

    .flights-tours_hide p{
        margin-bottom: unset !important;
    }

    .hidden-btn__more {
        justify-content: start;
    }

    .date-media {
        flex-wrap: wrap;
    }

    .flights-hotel {
        margin: 10px 0 10px 0;
    }

    .hotel-media {
        margin: 10px 0 0 0;
    }
}

@media(max-width:500px) {
    .fill__details {
        font-size: 18px;
    }

    .modalBook__content {
        width: 300px;
    }
}


@media (max-width:440px) {
    .flights__box {
        padding: 20px 20px;
    }

    .flights__box.hot {
        padding: 20px 20px;
    }

    .flights__box2 {
        padding: 20px 20px;
    }

    .top_first-child {
        align-items: center;
    }



    .flights-tours_hide p {
        font-size: 14px;
    }

    .flights-tours_hide b {
        font-size: 17px;

    }


}

@media (max-width:400px) {
    .modalBook__content {
        width: 300px;
        padding: 28px 10px 28px 32px;
    }

    .thingBook {
        font-size: 14px;
    }

    button.backto {
        margin: 0;
    }

    .friendly__buttons {
        justify-content: center;
        gap: 5px;
    }

    .backto {
        width: 130px;
        font-size: 15px;
    }

    .sendtoAdmin {
        width: 130px;
        font-size: 15px;
    }

    .two-hotelmap span {
        font-size: 15px;
    }

}

@media (max-width:375px) {
    .flights__box {
        padding: 20px 10px;
    }

    .flights__box.hot {
        padding: 20px 10px;
    }

    .flights__box2 {
        padding: 20px 10px;
    }

    .hot__title{
        font-size: 20px;
    }



    .modalBook__content {
        padding: 20px;
    }

    .hotel-media {
        margin: 15px 0 15px 0;
        justify-content: start;
    }


    .date-media {
        justify-content: start;
    }

    img.filht-ajy {
        width: 72px;
        height: 72px;
    }
}

@media (max-width:365px) {

    .flights-date {
        font-size: 14px;
    }

    .flight-plane {
        font-size: 14px;
    }

    .standart {
        font-size: 14px;
    }

    .flight-price {
        font-size: 14px;
    }

}



@media (max-width:355px) {
    .flights__box {
        padding: 20px 10px;
    }

    .flights__box2 {
        padding: 20px 10px;
    }

    .flights-date {
        font-size: 14px;
    }
}

@media (max-width:345px) {

    .modalBook__content {
        width: 260px;
    }

    .two-hotelmap span {
        font-size: 14px;
    }

    .flights__box {
        padding: 15px;
    }

    .flights__box2 {
        padding: 20px 10px 0 0;
    }

    .flights-tours_hide b {
        font-size: 14px;
    }
}

.description__wrappe {
    
    padding: 20px;
}

@media (max-width:335px) {
    .top-display p {
        font-size: 14px;
    }


}



@media (max-width:330px) {
    .flights-tours_hide p {
        font-size: 14px;
    }
}
