.header-book{
    background-image: url('../../../assets/imgs/new__umras.png') ;
    background-size: cover;
    background-position: center;
    height: 800px;
}


@media(max-width:705px){
    .header-book{
        height: 700px;
    }

}

@media(max-width:400px){
    .header-book{
        height: 500px;
    }

}








