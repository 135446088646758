@media (max-width: 1440px) {
  .application {
    padding: 0px;
    &__items {
      padding: 15px 0px;
      table {
        // display: none;
      }
    }
    &_res_list {
      // display: block;
    }
  }
  .res_list {
    // display: block;
  }
  .table {
    // display: none;
  }
  .application {
    &__price {
      form {
        justify-content: center;
        flex-wrap: wrap;
        gap: 30px;
      }
    }
    
  }
}

@media (max-width: 1360px) {
  .css-w3n3ud-control {
    margin-left: unset !important;
  }

  .flights {
  
    &__info{
      padding: 0px 16px;
    }
  }  
  .modal{
    &__content{
    width: fit-content;
    margin: 20px;
    height: -moz-fit-content;
    // height: 100%;
    overflow-y: scroll;
    background-color: white;
    padding: 0 14px 0 14px;
    border-radius: 12px;
    padding: 10px;
    }
  }
  .info{
    &_describe-text__none{
      display: none;
    }
    &_describe-text__flex{
      margin-left: 10px;
      display: flex;
    }
    &_describe-text{
      display: block;
    }
  }
 
          
  .application{
    .SelectCenter {
      display: flex;
      justify-content: start;
    }
    .info{
      &_describe-text__none{
        display: none;
      }
      &_describe-text__flex{
        display: flex;
        gap: 2px;
      }
      &_describe-text{
        display: block;
      }
      &_id{
        display: flex;
        align-items: center;
        gap: 5px;
      }
    }
  }

}

@media (max-width: 1280px) {
  // .form--item div{
  //   margin-left: unset;
  // }

  .application {
    &__form {
      .info {
        justify-content: center;
        flex-wrap: wrap;
      }
    }
  }
}

@media (max-width: 1100px) {
  .AddTour {
    padding: 27px 24px;

    .package {
      &-add {
        width: 100%;
      }
    }
  }

  .addtour__item {
    width: 100%;
  }

  .header {
    .logo_text {
      display: none;
    }
    .logo_burger {
      display: flex;
      padding: 13px;
    }
    &__box {
      display: none;
    }
    &__box2 {
      display: flex;
    }
    &__profile--title {
      display: none;
    }
  }
  .form {
    justify-content: center;
  }
  .wrapper {
    .main-container {
      margin-left: 0px;
    }
  }
  .sidebar {
    display: none;
  }
  .header__box_burger_icon {
    display: block;
  }
  .modal {
    width: 100%;
    left: 0px;
  }
  .create_home {
    padding: 0px;
  }
  .hostels {
    padding: 20px;
    @media (max-width: 500px) {
      padding: 10px;
    }
  }
  .hostels__row {
    width: 100%;
  }
  .excursion__data {
    padding-left: 0px;
  }
}

@media (max-width: 890px) {
  .profile {
  .editAvatar-block{
    .media_avatar{
      display: flex;
    }
  }
  }
  
  .profileAji {
    &-info__left {
      display: flex;
      // justify-content: center;
    }
  }
  .profile {
    padding: 20px 0 0 10px;
  }

  .profileAji__background{
    margin-top: 20px;
  }

  .user_list {
    flex-wrap: wrap;
  }
  .user_list .user_info_btns {
    margin-top: 20px;
    justify-content: center;
    width: 100%;
  }
}

@media (max-width: 700px) {
  .profile__about {
    padding: 40px 20px;
  }
  .profile__button {
    justify-content: space-evenly;
  }
  .profileAji {
    &-info__right {
      width: 100%;
    }

    &__blocksflex {
      display: block;
      width: 100%;
    }
    &-info__block {
      display: flex;
      justify-content: center;
    }
  }

  .page-404__background img {
    width: 600px;
    height: 400px;
  }
}

@media (max-width: 700px) {
  .blocks {
    display: block;
  }

  .user_list {
    display: block;
  }

  .about__phones-user {
    margin-top: 20px;
  }
  я .AjiHeads {
    display: flex;
    justify-content: space-around;
  }
}

@media (max-width: 800px) {
  .addtour__item-block{
    padding: 18px;
   .addtour__Item__raises{
    border-bottom: 1px solid #E2E8F0;
    .line__items{
      column-gap: 30px;
    }
   }
  }

  .first__trhree-mains {
    flex-wrap: wrap;
  }

  .the__main-title textarea {
    width: 100%;
  }
}

@media (max-width: 649px) {
  .first__trhree-mains {
    display: block;
  }

  .the__main-title input {
    width: 90%;
  }

  .the__main-title {
    margin-bottom: 20px;
  }

  .header__titles {
    .title {
      font-size: 13px;
      padding: 2px 0px;
    }
    .title2 {
      font-size: 13px;
      padding: 2px 0px;
    }
  }
  // .header__titles{
  //   overflow: scroll;
  //   display: flex;
  //   justify-content: space-between;
  //   padding: 0px;
  //   gap: 20px;
  //   .title{
  //     padding: 10px 0px;
  //   font-size: 15px;
  //   }
  // }
  .excursion__data {
    table {
      // display: none;
    }
    .excursion_res_list {
      // display: block;
    }
  }
  .form--item {
    input {
      width: 268px;
    }
  }

  .res_item {
    span {
      color: #000000;
    }
    .res_title {
      color: #718096;
    }
    .res_text {
      input {
        width: 261px;
      }
    }
  }
}

@media (max-width: 540px) {
  .first__two-mains {
    display: block;
  }
}

@media (max-width: 510px) {
  .about__phones-user {
    margin-top: 0;
  }
}

@media (max-width: 500px) {
  .user_list {
    display: flex;
    gap: 15px;
  }

  .user_list .user_info {
    flex: none;
  }

  .hotels {
    padding: 0px 15px;
  }
  .form--item {
    width: 281px;
  }
  .modal_blocks {
    // display: block;
    padding-top: 20px;
    .block1 {
      padding-top: 20px;
    }
  }
  .header {
    &__titles {
      gap: 30px;
      justify-content: center;
    }
    &__profile--title {
      // display: none;
    }
  }
  .Visa {
    .visa_form {
      .price {
        // display: block;

        div {
          display: flex;
          justify-content: center;
          padding-top: 20px;
        }

        input {
          width: 94%;
        }
      }
    }
  }
}

@media (max-width: 430px) {
  .hostels{
    padding: 16px;
  }
  .calculator{
    width: 100%;
    text-align: center;
    .calculator__title{
      text-align: start;
    }
  }
  .page {
    margin: 0px;
  }
  .header__titles {
    padding: 0px;
    justify-content: space-between;
    padding: 0px 10px;
  }

  .AjiHeads {
    padding: 0 10px;
  }

  .user_info_btns {
    button {
      font-size: 14px;
      padding: 10px 15px;
    }
  }
}
@media (max-width: 400px) {
  .instagram__photos {
    display: flex;
    justify-content: center;

    &_block {
      width: 0;
    }
  }
  .addtour__item-block .addtour__Item__raises .line-price {
    display: block;
    padding: 14px;
  }

  .addtour__item-block .addtour__Item__raises .line__items__text {
    font-size: 14px;
  }

  .addtour__item-block .addtour__Item__raises .line__items__textblue {
    font-size: 16px;
  }

  .addtour__item-block .addtour__Item__raises .line-price__text2 {
    font-size: 16px;
  }

  .addtour__item-block .addtour__Item__raises .line-price__text1 {
    font-size: 14px;
  }
  .user_info.active {
    margin-top: 20px;
  }

  .AddTour {
    padding: 27px 24px 0 5px;
  }

  .flights__table {
    tbody {
      tr {
        padding: 0px 0px;
      }
    }
  }
  .header {
    &__titles {
      overflow-x: scroll;
      padding-top: 20px;
      // gap: 14px;
      &::-webkit-scrollbar {
        display: none;
      }
      .title {
        font-size: 16px;
        padding: 2px 0px;
      }
      .title2 {
        font-size: 16px;
        padding: 2px 0px;
      }
    }
  }

  .ControlPanel {
    padding: 27px 16px;
  }
  // .header {
  //   &__titles {
  //     gap: 0;
  //     justify-content: center;
  //   }
  // }
}

@media (max-width: 375px) {
  .profile__button {
    .exit {
      font-size: 14px;
    }
    button {
      font-size: 14px;
    }
  }
  .profile__save {
    button {
      font-size: 14px;
    }
  }
  .header__titles {
    .title {
      font-size: 16px;
      padding: 2px 0px;
    }
    .title2 {
      font-size: 16px;
      padding: 2px 0px;
    }
  }
}

@media (max-width: 350px) {
  .profile__button {
    .exit {
      font-size: 12px;
    }
    button {
      font-size: 12px;
    }
  }
  .form--item {
    input {
      width: 235px;
    }
  }
  .info_id{
    div{

    font-size: 28px;
    font-weight: 600;
    }
  }
  .profile-change {
    padding: 0;
    padding-top: 20px;
  }
  .info_describe-text{
    font-size: 14px;
  }
  .info_describe-text__flex{
    label{
      font-size: 14px;
    }
  }
  td{
    div{
      font-size: 14px;
    }
  }
  .header {
    &__titles {
      .title {
        font-size: 14px;
      }
      .title2 {
        font-size: 14px;
      }
    }
  }

  // .btn {
  //   &.add {
  //     width: 281px;
  //   }
  // }
}
