.sidebar {
  overflow: scroll;
  position: fixed;
  left: 0;
  top: 0;
  width: 250px;
  height: 100vh;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  z-index: 1;

  &::-webkit-scrollbar {
    display: none;
  }

  .logo {
    padding: 25px 81px;
  }

  &__main {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .links {
    display: flex;
    flex-direction: column;
    // align-items: center;

    gap: 16px;
  }

  &__link {
    height: 48px;
    display: flex;
    align-items: center;
    gap: 12px;
    line-height: 20px;
    font-family: 'Lato', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;

    color: #a9a9a9;

    img {
      fill: #125d98;
    }

    &.active {
      img {
        filter: invert(65%) sepia(30%) saturate(678%) hue-rotate(881deg) brightness(79%) contrast(159%);
      }

      height: 40px;
      width: 172px;
      background: #f5f8fe;
      padding-left: 16px;
      border-left: #125d98 solid 4px;
      border-radius: 6px;

      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 15px;
      line-height: 160%;
      color: #125d98;
    }
  }

  button {
    width: 250px;
    height: 90px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;
    background: #ffffff;
    font-style: normal;
    font-weight: 700;
    font-size: 18px;
    line-height: 22px;
    color: #7d7d7d;
    border-radius: 0;
    .button__image {
      margin-right: 20px;
    }
  }
}

@media screen and (min-width: 328px) and (max-width: 375px) {
  .sidebar {
    display: none;
  }
}
