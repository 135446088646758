header {
    width: 100%;
}

.header-menu {
    padding: 23px 0;
}

.wrapper-home {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navs-fixed {
    display: flex;
    gap: 120px;
}





.header-menu__fixed {
    padding: 23px 0px;
    width: 100%;
    position: fixed;
    z-index: 5;
    top: 0;
    background-color: white;
}


.wrapper-home__nav-barses ul {
    display: flex;
    gap: 60px;
}



.wrapper-home__nav-barses ul a {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #fff;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.wrapper-home__nav-barses ul a:hover {
    color: #3FA0EC;
    border-bottom: 1px solid #3FA0EC;
}



.wrapper-home__nav-barses ul a.active {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #3FA0EC;
    ;
    border-bottom: 1px solid #3FA0EC;
    ;
}

.wrapper-home__comees-in a {
    display: flex;
    align-items: center;
    gap: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #fff;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.wrapper-home__comees-in a:hover {
    color: #3FA0EC;
    border-bottom: 1px solid #3FA0EC;
}


.wrapper-home__comees-in a img {
    display: flex;
    align-items: center;
    gap: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #fff;
    cursor: pointer;
}

.wrapper-home__comees-in a img{
    transition: 0.3s ease-in-out;
}

.wrapper-home__comees-in a:hover img {
    filter: invert(50%) sepia(55%) saturate(677%) hue-rotate(167deg) brightness(104%) contrast(85%);
}


.wrapper-home__comees-in a {
    display: flex;
    align-items: center;
    gap: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #fff;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.wrapper-home__comees-in a.active {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #3FA0EC;
    ;
    border-bottom: 1px solid #3FA0EC;
}

.inside__to-block-menu {
    display: flex;
    gap: 100px;
}

.header-menu__fixed .wrapper-home__nav-barses ul a {
    color: black;
    transition: 0.3s ease-in-out;
}

.header-menu__fixed .wrapper-home__nav-barses ul a:hover {
    color: #125D98;;
    border-bottom: 1px solid #125D98;
}



.header-menu__fixed .wrapper-home__nav-barses ul a.active {
    color: #125D98;;
    border-bottom: 1px solid #125D98;
}

.header-menu__fixed .wrapper-home__comees-in a {
    color: black;
    transition: 0.3s ease-in-out;
}

.header-menu__fixed .wrapper-home__comees-in a:hover {
    color: #125D98;;
    border-bottom: 1px solid #125D98;
}

.header-menu__fixed .wrapper-home__comees-in a img {
    filter: invert(100%) sepia(100%) saturate(7432%) hue-rotate(210deg) brightness(100%) contrast(115%);
}

.header-menu__fixed .wrapper-home__comees-in a:hover img {
    filter: invert(50%) sepia(55%) saturate(677%) hue-rotate(167deg) brightness(104%) contrast(85%);

}

.hidden__menu {
    display: none;
    cursor: pointer;
    filter: invert(1000%) sepia(96%) saturate(7488%) hue-rotate(104deg) brightness(103%) contrast(95%)
}

.hidden__menu-white{
    display: none;
}

.hidden__menu-white img {
    width: 30px;
    height: 30px;
}

.header-menu__fixed .hidden__menu-white img.seconder {
    filter: invert(1000%) sepia(96%) saturate(7488%) hue-rotate(104deg) brightness(103%) contrast(95%)
}


.wrapper-home__logo2 {
    display: none;
}




@media (max-width : 1080px) {
    .wrapper-home__nav-bars ul {
        display: flex;
        gap: 40px;
    }

}


@media (max-width:875px) {
    .hidden__menu {
        display: inline;
    }

    .hidden__menu-white {
        display: inline;
    }

    .navs-fixed.normal {
        gap: 0;
    }

    .wrapper-home__logo2 {
        display: inline;
    }

    .hide_to-pixelfuck {
        display: none;
    }

    .wrapper-home__nav-barses ul {
        display: none;
    }

    .wrapper-home__comees-in {
        display: none;
    }
}