.instafeed img.insta {
  border-radius: 16px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  background-size: cover;
  height: 500px;
  object-fit: cover;
  width: 385px;
  max-width: 385px;
}

.insta-buttons {
  max-width: 364px;
  margin: 0 auto;
  display: block;
}
