.ajy-wrapper{
    max-width: 1184px;
    margin: 46px auto;
}

.ajy-wrapper h1{
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    color: #125D98;
    margin-bottom: 60px;

}


.profile__Image{
    display: flex;
    justify-content: center;
}

.all__profile{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 52px;
}

.profile2{
    padding: 40px 0;
    width: 360px;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.04);
    border-radius: 12px;
}

.profile2 img{
    text-align: center;
}

.profile2  h2{
    padding: 23px 0 8px 0;
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 24px;
    line-height: 36px;
    color: #112F53;
} 

.profile2 p{
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #A6A6A6;
    opacity: 0.6;
    margin-bottom: 28px;
}


.look-profile{
    display: flex;
    justify-content: center;
}


.look-profile button{
    padding: 12px 16px;
    background: #125D98;
    border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    cursor: pointer;
    /* transition-property: transform;
    transition-duration: 0.3s;
    transition-timing-function: ease;
    transition-delay: 0s; */
    transition: transform 0.3s, background-color 0.3s, color 0.3s;
}

.look-profile button:hover{
    transform: translateY(-5px);
    background-color: var(--clr-accent);
    color: var(--clr-light);
    border: 1px solid #125D98;
}


@media (max-width:1213px){
    .all__profile{
        justify-content: center;
    }
}



@media (max-width:802px){
    .all__profile{
        justify-content: center;
    }
}

@media (max-width:400px){
    .all__profile{
        justify-content: center;
    }

    .profile2 h2{
        font-size: 20px;
    }

    .ajy-wrapper h1{
        font-size: 26px;
    }

    .ajy-wrapper{
        margin: 0 auto;
    }
    

}


@media (max-width:375px){


}



@media (max-width:350px){
    .ajy-wrapper h1{
        font-size: 20px;
    }

    .profile2 h2{
        font-size: 16px;
    }

    .profile2 p{
        font-size: 14;
    }


}