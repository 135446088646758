header {
    width: 100%;
}

.header-menu {
    padding: 23px 0;
}

.wrapper-home {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navs-fixed {
    display: flex;
    gap: 280px;
}





.header-menu__fixed {
    padding: 23px 0px;
    width: 100%;
    position: fixed;
    z-index: 5;
    top: 0;
    background-color: white;
}


.wrapper-home__nav-bars ul {
    display: flex;
    gap: 60px;
}



.wrapper-home__nav-bars ul a {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #000;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.wrapper-home__nav-bars ul a:hover {
    color: #3FA0EC;
    border-bottom: 1px solid #3FA0EC;
}

.wrapper-home__nav-bars ul a.active {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #3FA0EC;
    ;
    border-bottom: 1px solid #3FA0EC;
    ;
}

.wrapper-home__come-in a {
    display: flex;
    align-items: center;
    gap: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #fff;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.wrapper-home__come-in a:hover {
    color: #3FA0EC;
    border-bottom: 1px solid #3FA0EC;
}

.wrapper-home__come-in a img {
    display: flex;
    align-items: center;
    gap: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #fff;
    cursor: pointer;
    transition: 0.4s ease-in-out;
}

.wrapper-home__come-in a:hover img {
    filter: invert(50%) sepia(55%) saturate(677%) hue-rotate(167deg) brightness(104%) contrast(85%);
}


.wrapper-home__come-in a {
    display: flex;
    align-items: center;
    gap: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #000;
    cursor: pointer;
}




.wrapper-home__come-in a.active {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #3FA0EC;
    ;
    border-bottom: 1px solid #3FA0EC;
}

.wrapper-home__come-in a.active img {
    filter: invert(27%) sepia(63%) saturate(1363%) hue-rotate(177deg) brightness(94%) contrast(89%);
}


.wrapper-home__come-in a img {
    filter: invert(100%) sepia(100%) saturate(7432%) hue-rotate(210deg) brightness(100%) contrast(115%);
}

.inside__to-block-menu {
    display: flex;
    gap: 100px;
}

.header-menu__fixed .wrapper-home__nav-bars ul a {
    color: black;
}


.header-menu__fixed .wrapper-home__nav-bars ul a.active {
    color: #125D98;
    ;
    border-bottom: 1px solid #125D98;
}

.header-menu__fixed .wrapper-home__come-in a {
    color: black;
}

.header-menu__fixed .wrapper-home__come-in a img {
    filter: invert(100%) sepia(100%) saturate(7432%) hue-rotate(210deg) brightness(100%) contrast(115%);


}


.hidden__menu {
    display: none;
    cursor: pointer;
}

.hidden__menu img.seconder {
    width: 30px;
    height: 30px;
}

.hidden__menu img.seeconder {
    width: 30px;
    height: 30px;
    filter: invert(0%) sepia(100%) saturate(7432%) hue-rotate(210deg) brightness(160%) contrast(115%);

}

.hidden__menu img.firster {
    width: 30px;
    height: 30px;
    filter: invert(100%) sepia(160%) saturate(7432%) hue-rotate(210deg) brightness(160%) contrast(200%);

}



.wrapper-home__logo2 {
    display: none;
}




/* Button */

* {
    user-select: none;
    -webkit-tap-highlight-color: transparent;
  }
  
  *:focus {
    outline: none;
  }

.toggle-button-cover {
    position: relative;
    width: 100px;
  }
  
  .button-cover,
  .knobs,
  .layer {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  

.button {
    position: relative;
    top: 50%;
    width: 74px;
    height: 36px;
    margin: -17px auto 0 auto;
    overflow: hidden;
  }
  
  .button.r,
  .button.r .layer {
    border-radius: 100px;
  }
  
  .button.b2 {
    border-radius: 2px;
  }
  
  .checkbox {
    position: relative;
    width: 100% !important;
    height: 100%;
    padding: 0;
    margin: 0;
    opacity: 0;
    cursor: pointer;
    z-index: 3;
  }
  
  .knobs {
    z-index: 2;
  }
  
  .layer {
    width: 100%;
    background-color: #ebf7fc;
    transition: 0.3s ease all;
    z-index: 1;
  }
  


#button-3 .knobs:before {
    content: "RU";
    position: absolute;
    top: 4px;
    left: 4px;
    width: 20px;
    height: 10px;
    color: #fff;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 9px 4px;
    background-color: #125D98;
    border-radius: 50%;
    transition: 0.3s ease all, left 0.3s cubic-bezier(0.18, 0.89, 0.35, 1.15);
  }

  #button-3 .knobs:after {
    content: "KG";
    position: absolute;
    top: 4px;
    left: 42px;
    width: 20px;
    height: 10px;
    color: #000;
    font-size: 10px;
    font-weight: bold;
    text-align: center;
    line-height: 1;
    padding: 9px 4px;
}
  
 
  #button-3 .checkbox:checked + .knobs:before {
    content: "KG";
    left: 42px;
    background-color: #125D98;
  }

  #button-3 .checkbox:checked + .knobs:after {
    content: "RU";
    left: 4px;
  }
  
  #button-3 .checkbox:checked ~ .layer {
    background-color: #ebf7fc;
  }
  

@media (max-width : 1080px) {
    .wrapper-home__nav-bars ul {
        display: flex;
        gap: 40px;
    }

}

@media (max-width:875px) {
    header .toggle-button-cover{
        display: none;
    }

    .hidden__menu {
        display: inline;
    }

    .wrapper-home__logo2 {
        display: inline;
    }


    .hide_to-pixelfuck {
        display: none;
    }


    .wrapper-home__nav-bars ul a {
        display: none;
    }

    .wrapper-home__come-in {
        display: none;
    }


    .navs-fixed.normal {
        gap: 0;
    }
}
