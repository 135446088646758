.pagination_main {
  padding-top: 35px;
  display: flex;
  justify-content: center;

  .pagination_children {
    display: flex;
    align-items: center;
    gap: 8px;

    .arrow {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 32px;
      height: 32px;
      cursor: pointer;

      background: #ffffff;
      border: 1px solid #dfe3e8;
      border-radius: 4px;

      &.disable {
        background: #919eab;
        opacity: 0.5;
        cursor: auto;
      }
    }

    button {
      padding: 0;
    }

    .pagination_number {
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 32px;
      height: 32px;
      background: #ffffff;
      border: 2px solid #dfe3e8;
      border-radius: 4px;
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #112f53;

      &.checked {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 32px;
        height: 32px;
        background: #ffffff;
        border: 2px solid #125d98;
        border-radius: 4px;
        font-family: "Lato", sans-serif;
        font-style: normal;
        font-weight: 700;
        font-size: 14px;
        line-height: 20px;
        text-align: center;
        color: #125d98;
        cursor: pointer;
      }
    }
  }
}
