.hadja-wrapper{
    max-width: 1116px;
    margin: 124px auto;
}

.hadja__list{
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 48pxp;
    color: #125D98;

}

.hadja-wrapper__inside{
    margin-top: 51px;
    display: flex;
    gap: 52px;
    align-items: center;
}

.hadja-right__text{
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 32px;
display: flex;
align-items: center;

color: #112F53;
}


@media(max-width:870px){
    .hadja-wrapper__inside{
        display: block;
        text-align: center;
    }
}

@media (max-width:520px ){
    .hadja-inside__left img{
        width: 400px;
    }
    .hadja-wrapper{
        margin-bottom: 0;
    }
}

@media (max-width:520px ){
    .hadja-inside__left img{
        width: 300px;
    }
}

@media (max-width:425px ){
    .hadja-right__text{
        font-size: 17px;
    }

    .hadja__list{
        font-size: 26px;
    }
}


@media (max-width:380px ){
    .hadja-right__text{
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 160%;
    }

    .hadja__list{
        font-size: 20px;
    }
}



@media (max-width:340px){
    .hadja-inside__left img{
        width: 280px;
    }
}