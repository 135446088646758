@import url('https://fonts.googleapis.com/css2?family=Comfortaa:wght@400;700&family=Lato:wght@400;700&display=swap');


.video__zamchik{
    background-image: url('../../assets/img/GIF325235.gif') ;
    background-size: cover;
    background-position: center;
    height: 650px;
    position: relative;
    z-index: 1;
}


.goluboi__background{
    background-image: url('../../assets/imgs/left-blue.png');
    position: absolute;
    height: 650px ;
    top: 0;
    left: 0;
    z-index: 2;
    border: none;
}


.video__zamchik::after{
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: rgba(0, 0, 0, 0.6);
}

.menu-block__side{
    position: absolute;
    top: 0;
    z-index: 4;
    right: 0;
    left: 100px;
}


.will-be__Mecca{
    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 27px;
    color: #FFFFFF;
    /* transform: matrix(1, 0, 0, 1, 0, 0); */
}

.umra-in__ramadan{
    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 120%;
    text-transform: lowercase;
    color: #FFFFFF;

}

.goluboi-zam_zam{
    padding: 100px 200px 0 34px;
}

.image-zam_zam{
    display: flex;
    justify-content: center;
}

.will-be__Mecca{
    margin-top: 42px;
    text-align: center;
}

.umra-in__ramadan{
    max-width: 513px;
    margin-top: 20px;
    text-align: center;
}

.hots__presents{
    margin-top: 24px;
    display: flex;
    justify-content: center;
}

.hots__presents-hide{
    position: absolute;
    top: 0;
    width: 100%;
    height: 40%;
    display: none;
    z-index: 3;
}


.hot__slices{
    padding: 20px 117px 21px 116px;
    font-family: 'Comfortaa';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 120%;
    color: #125D98;
    background: #FFFFFF;
    border-radius: 8px;
    height: 60px;
}

@media(max-width:705px){
    .menu-block__side{
        position: absolute;
        right: 0;
        left: 0;
    }

}

@media(max-width:440px){
    .hot__slices{
        padding: 18px 80px 19px 79px;
    }

}

@media(max-width:400px){
    .hot__slices{
        padding: 14px 60px 15px 59px;
        font-size: 15px;
    }

}