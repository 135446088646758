.footer {
  margin-top: 47px;
  border-top: 1px solid rgba(18, 93, 152, 0.08);
}

.prolabhref {
  font-family: "Lato", sans-serif;
  padding-left: 8px;
  padding-right: 8px;
  color: #0a142f !important;
}

.prolabhref span {
  padding-left: 8px;
}

.footer-wrapper {
  max-width: 1089px;
  margin: 0px auto;
  padding: 0 0 30px 0;
}

.left-side__footer {
  padding-top: 38px;
  display: flex;
  gap: 217px;
}

.left__footer {
  display: flex;
  gap: 60px;
}

.top-side__footer {
  display: flex;
  gap: 116px;
}

.top__text {
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #112f53;
}

.footer__nav-bar {
  display: flex;
  gap: 72px;
}

.bar__about-us ul li {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 26px;
  color: #0a142f;
}

@keyframes lisAll {
  from {
    transform: translateX(8px);
  }

  to {
    transform: translateX(0);
  }
}

.bar__about-us ul a li:hover {
  animation: lisAll 1s ease-in-out both;
  color: #125d98;
  border-bottom: 1px solid #125d98;
}

.bar__about-us ul a.active li {
  display: flex;
  justify-content: center;
  color: #125d98;
  border-bottom: 1px solid #125d98;
}

.bar__about-us ul a li {
  margin-bottom: 8px;
}

.bar__online-contacts ul li {
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 24px;
  opacity: 0.7;
  color: #0a142f;
}

.bar__online-contacts ul li {
  margin-bottom: 9px;
}

.icon-arrow {
  margin-top: 30px;
  width: 52px;
  height: 52px;
  background: #125d98;
  border-radius: 50%;
  padding: 15px 20px 17px 15px;
  color: white;
  font-size: 24px;
  cursor: pointer;
}

.bottom__footer {
  display: flex;
  align-items: flex-end;
  margin-top: 34px;
}

/* .left-bottom__footer {
    display: flex;
    gap: 32px;
} */

.number {
  margin-bottom: 20px;
}

.number a {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: #0a142f;
  border-bottom: 1px solid #125d98;
  cursor: pointer;
}

.maket-name,
.maket-name a {
  display: flex;
  align-items: flex-end;
  font-style: normal;
  font-weight: 300;
  font-size: 16px;
  line-height: 20px;
  color: #0a142f;
  opacity: 0.65;
}

.maket-name a {
  text-decoration: none;
  color: #000000;
  opacity: 1;
}

.maket-name a {
  color: #125d98;
}

.gmail-contact a {
  font-style: normal;
  font-weight: 300;
  font-size: 14px;
  line-height: 22px;
  color: #0a142f;
  border-bottom: 1px solid #125d98;
  cursor: pointer;
}

.right-bottom__footer {
  display: flex;
  justify-content: center;
  margin-top: 40px;
}

@media (max-width: 795px) {
  .footer-wrapper {
    display: flex;
    justify-content: center;
  }

  .left-side__footer {
    display: block;
  }

  .footer__nav-bar {
    margin-top: 20px;
    justify-content: center;
  }

  .bottom__footer {
    display: block;
    justify-content: center;
  }

  .number {
    display: flex;
    justify-content: center;
  }

  .gmail-contact {
    display: flex;
    justify-content: center;
  }

  .maket-name {
    margin-top: 10px;
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 430px) {
  .maket-name {
    font-size: 14px;
    display: flex;
    gap: 10px;
  }

  .prolabhref {
    display: none;
  }
}

@media (max-width: 425px) {
  .left__footer {
    display: block;
  }

  .top-side__footer {
    display: block;
  }

  .top__text {
    display: flex;
    justify-content: center;
    font-size: 20px;
  }

  .footer__icon {
    display: flex;
    justify-content: center;
  }

  .footer-wrapper {
    margin: 0;
  }

  .top__text:not(:last-child) {
    margin-top: 20px;
  }
}
