header{
    width: 100%;
}

.header-menued{
    padding: 43px 0;
}

.wrapper-home{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.navs-fixed{
    display: flex;
    gap: 150px;
}





.header-menued__fixed{
    padding: 23px 0px;
    width: 100%;
    position: fixed;
    z-index: 5;
    top: 0;
    background-color: white;
}


.wrapper-home__nav-barss ul{
    display: flex;
    gap: 43px;
}



.wrapper-home__nav-barss ul a{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #fff;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}


.wrapper-home__nav-barss ul a:hover{
    transform: scale(1.03);
    color: #3FA0EC;
    border-bottom: 1px solid #3FA0EC;
}


.wrapper-home__nav-barss ul a.active{
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color:  #3FA0EC;;
    border-bottom: 1px solid #3FA0EC;; 
}

.wrapper-home__come-ins a{
    display: flex;
    align-items: center;
    gap: 10px;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 26px;
    color: #fff;
    cursor: pointer;
    transition: 0.3s ease-in-out;
}

.wrapper-home__come-ins a:hover{
    transform: scale(1.03);
    color: #3FA0EC;
    border-bottom: 1px solid #3FA0EC;
}

.wrapper-home__come-ins a img{
    transition: 0.4s ease-in-out;
}

.wrapper-home__come-ins a:hover img{
    transform: scale(1.03);
    filter: invert(50%) sepia(55%) saturate(677%) hue-rotate(167deg) brightness(104%) contrast(85%);
}




.inside__to-block-menu{
    display: flex;
    gap: 50px;
}

.header-menued__fixed .wrapper-home__nav-barss ul a{
    color: black;
}

.header-menued__fixed .wrapper-home__nav-barss ul a:hover{
    transform: scale(1.03);
    color: #125D98;;
    border-bottom: 1px solid #125D98;
}

.header-menued__fixed .wrapper-home__nav-barss ul a.active{
    color: #125D98;;
    border-bottom: 1px solid #125D98;
}

.header-menued__fixed .wrapper-home__come-ins a{
    color: black;
}

.header-menued__fixed .wrapper-home__come-ins a:hover{
    transform: scale(1.03);
    color: #125D98;;
    border-bottom: 1px solid #125D98;
}

.header-menued__fixed .wrapper-home__come-ins a img{
    transition: 0.3s ease-in-out;
    filter: invert(100%) sepia(100%) saturate(7432%) hue-rotate(210deg) brightness(100%) contrast(115%);

}

.header-menued__fixed .wrapper-home__come-ins a:hover img{
    transform: scale(1.03);
    filter: invert(50%) sepia(55%) saturate(677%) hue-rotate(167deg) brightness(104%) contrast(85%);

}
.navs-fixed.normal .hidden__menued img{
    filter: invert(1000%) sepia(96%) saturate(7488%) hue-rotate(104deg) brightness(103%) contrast(95%);
}


.hidden__menued{
    display: none;
    cursor: pointer;
}

.hidden__menued img{
    width: 30px;
    height: 30px;
}


.wrapper-home__logo2{
    display: none;
}




@media (max-width : 1080px){
    .wrapper-home__nav-barss ul{
        display: flex;
        gap: 40px;
    }
    
}


@media (max-width:875px){   
    .hidden__menued{
        display: inline;
    }

    .hidden__menued img{
        width: 21px;
    }

    .menu-block__side{
        position: absolute;
        right: 0;
        left: 0;
    }

    .hots__presents-hide{
        display: flex;
        justify-content: center;
        align-items: flex-end;
    }

    .goluboi__background{
        display: none;
    }

    .video__zamchik{
        height: 670px;
    }

    .navs-fixed.normal{
        gap: 0;
    }

    .wrapper-home__logo2{
        display: inline;
    }

    

   .hide_to-pixelfuck{
    display: none;
   }

    .header-menued__fixed .hidden__menued img path{
        fill: #000;
    }

    .wrapper-home__nav-barss ul{
        display: none;
    }

    .wrapper-home__come-ins{
        display: none;
    }
}


@media (max-width:500px){
    .video__zamchik{
        height: 500px;
    }
}