a{
    cursor: pointer;
}

.main-wrapper {
    margin: 77px auto;
    display: flex;
}


.main-wrapper__zam-zam {
    background: rgba(225, 244, 255, 0.17);
    border: 1px solid #E1F4FF;
    border-radius: 12px;
    padding: 50px 60px 60px 60px;
}

.main-wrapper__arab {
    width: 180px;
}

.main-wrapper__right {
    margin-top: 90px;
}

.main-wrapper__zam-zam img{
    /* width: 385px; */
    /* height: 523px; */
}

@media (max-width:481px){
    .main-wrapper__zam-zam img{
        width: 100% !important;
    }
}



.main-wrapper__zam-zam video{
    width: 385px;
    height: 523px;
}

.zamzam-inside {
    background: #FFFFFF;
    border: 1px solid #DAEDFB;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.08);
    border-radius: 16px;
    padding: 0 0 20px 0;
}

img.imageInMecca {
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
}

.main-wrapper__online-contacts {
    margin-top: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 27px;
}

.main-contacts__radius a {
    border-radius: 50%;
    padding: 12px 12px 8px 12px;
}

.main-contacts__radius a:hover {
    background-color: #125D98;
    color: white;
    border-radius: 50%;
    font-size: 24px;
    cursor: pointer;
}

.main-contacts__radius a:hover img{
    filter: invert(100%) sepia(100%) saturate(2%) hue-rotate(151deg) brightness(150%) contrast(101%);
}


.main-wrapper__arab img {
    position: relative;
    left: -120px;
    top: -70px;
    z-index: 1;
}

.nrav {
    padding: 10px 10px 0 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.liked-by {
    padding: 10px 0 0 10px;
}


.persons__liked {
    font-size: 16px;
}

.left-class__post {
    display: flex;
    gap: 5px;
    align-items: center;
}

.nrav svg {
    cursor: pointer;
}


.right-inside__turist-reality {
    font-weight: 600;
    font-size: 32px;
    line-height: 38px;
    color: #000000;
    margin-bottom: 20px;
}

.right-inside__hotel-place {
    font-style: normal;
    font-weight: 300;
    font-size: 19px;
    line-height: 30px;
    display: flex;

    color: #0C0C0C;

}

.right-inside__bars {
    display: flex;
    gap: 59px;
    margin-top: 54px;
}

@media (max-width : 350px) {
    .right-inside__bars {
        gap: 25px;
    }
}

.left-bar__list {
    display: flex;
    gap: 16px;
    align-items: center;
}

.right-bar__list {
    display: flex;
    gap: 16px;
    align-items: center;
}

.left-bar__list b {
    padding: 6px 11px;
    background: #125D98;
    border-radius: 89px;
    color: white;
    font-size: 16px;
}

.left-bar__list:not(:last-child) {
    margin-bottom: 28px;
}

.right-bar__list b {
    padding: 6px 11px;
    background: #125D98;
    border-radius: 89px;
    color: white;
    font-size: 16px;
}

.right-bar__list:not(:last-child) {
    margin-bottom: 28px;

}

/* Media Quares  */
@media (max-width:1050px) {
    .main-wrapper__arab img {
        display: none;
    }

    .main-wrapper__arab {
        width: 0;
    }

    .main-wrapper {
        display: flex;
        gap: 30px;
    }

    .main-wrapper__right {
        margin-top: 0;
    }

}

@media (max-width:830px) {
    .main-wrapper {
        display: block;
    }

    .main-wrapper__zam-zam {
        justify-content: center;
    }

    .zamzam-inside {
        max-width: 385px;
        text-align: center;
    }

    .persons__liked {
        text-align: left;
    }


    .main-wrapper__online-contacts {
        justify-content: center;
    }

    .main-wrapper__right {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .right-inside__turist-reality {
        text-align: start;
        font-size: 28px;
        margin-top: 10px;
    }

    .right-inside__hotel-place {
        text-align: start;
        font-size: 16px;
    }

    .right-inside__bars {
        justify-content: center;
    }

}


@media (max-width: 580px) {
    .image-about__us {
        margin-top: 30px;
        width: 500px;

    }

    .main-wrapper__zam-zam {
        padding: 20px;
    }
}

@media (max-width: 440px) {

    img.imageInMecca {
        width: 320px;
    }

    .persons__liked {
        font-size: 14px;
    }

    .right-inside__turist-reality {
        margin-left: 10px;
        font-size: 25px;
    }

    .right-inside__hotel-place {
        margin-left: 10px;
    }

    .image-about__us {
        width: 350px;

    }
}


@media(max-width:410px) {
    img.imageInMecca {
        width: 280px;
    }
}

@media (max-width:375px) {
    .right-inside__turist-reality {
        font-size: 20px;
    }

    .right-inside__hotel-place {
        font-size: 14px;
    }

    img.imageInMecca {
        width: 250px;
    }
}


@media (max-width:340px) {
    img.imageInMecca {
        width: 230px;
    }
}