.agent {
  padding: 16px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 19px;
  text-align: center;

  color: #112f53;

  border: 1px solid #000000;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);

  &:hover {
    background-color: #71c1ff;
    cursor: pointer;
  }
}
