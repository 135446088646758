.header {
  height: 80px;
  background: #ffffff;
  border-left: 1px solid #f5f5f5;

  .menu__cover {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    right: 0;
    top: 80px;
    background-color: #49464620;
  }

  &__titles {
    padding: 20px;
    display: flex;
    gap: 30px;
    border-bottom: 1px solid rgba(209, 213, 219, 0.5);
    overflow-x: scroll;
    scroll-behavior: smooth;

    .title {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #112f53;
      white-space: nowrap;

      &:hover {
        border-bottom: 2px solid #125d98;
        color: #125d98;
        cursor: pointer;
      }

      &.active {
        border-bottom: 2px solid #125d98;
        color: #125d98;
      }
    }
    .title2 {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      text-align: center;

      color: #112f53;

      &:hover {
        border-bottom: 2px solid #125d98;
        color: #125d98;
        cursor: pointer;
      }

      &.active {
        border-bottom: 2px solid #125d98;
        color: #125d98;
      }
    }
  }

  &__titles::-webkit-scrollbar {
      display: none;
  }

 
  

  &__res {
    display: none;
  }

  &__box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px 15px;
    
    .how__this-is__user b{
      color: #125d98;
      border-bottom:  1px solid #125d98;
    }


    &__text {
      display: flex;
      align-items: center;
      gap: 56px;
      .logo_burger {
        display: none;

        img {
          cursor: pointer;
        }
      }

      .logo_text {
        font-style: normal;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        color: #a0aec0;
        padding: 28px;
      }
    }

    &_burger_icon {
      display: none;
    }
  }

  &__profile {
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;

    &--menu{
      position: absolute;
      top: 60px;
      right: 0;
      left: -2px;
      width: 100%;
      background-color: #fff;
      border: #a0aec0 1px solid;
      border-top: none;
      padding: 8px 0 8px 16px;
      z-index: 10;

      div {
        padding: 8px;
        cursor: pointer;
      }

      .logout {
        color: #f76659;
      }
    }

    &--title {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #000000;
      display: flex;
      align-items: center;
    }

    &--photo {
      margin-left: 10px;
      border-radius: 50px;
      width: 40px;
      height: 40px;
    }
  }
  &__box2 {
    display: none;
    justify-content: space-between;
    align-items: center;
  }
}

.MenuBurger {
  display: flex;
  justify-content: start;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 100;
  background-color: rgb(0, 0, 0, 0.277);
  position: fixed;

  &_content {
    width: 250px;
    height: 100vh;
    background-color: #ffffff;

    .burger {
      // overflow: scroll;
      height: 100vh;
      position: fixed;
      left: 0;
      top: 0;
      width: 250px;
      background: #fff;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      z-index: 1;

      &__main {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        padding-top: 15px;
      }
      .menu_btn {
        display: flex;
        justify-content: center;
        padding-bottom: 15px;
      }

      &_links {
        display: flex;
        flex-direction: column;
        padding-top: 15px;
        gap: 5px;
      }
    }
  }
}

.control_header {
  font-family: 'Lato', sans-serif;

  &_text {
    display: flex;
    gap: 25px;
    padding-left: 30px;
    padding-top: 30px;
    padding-bottom: 20px;

    .control_route_text {
      &:hover {
        text-decoration-line: underline;
        color: #125d98;
        cursor: pointer;
      }
    }
  }
}
