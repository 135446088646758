.flights-wrapper__book{
    max-width: 1276px;
    margin: 42px auto;
    background: #FFFFFF;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.04);
    
}

.top-display__book{
    display: flex;
    gap: 26px;
}

.left__hotel{
    max-width: 462px;
}


.display__root{
    display: flex;
    gap: 26px;
}

.display__root img{
    border-radius: 50%;
}

.right__side-book{
    display: flex;
    justify-content: flex-end;
    position: relative;
    top: -50px;
}

.flights-wrapper__text{
    text-align: center;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 48px;
    color: #000000;
}

.inner__book{
        padding: 0px 56px 28px 118px;
}

.flights__box:not(:last-child){
    margin-bottom: 28px;
}



.flights-tours b{
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    color: #112F53;
    border-bottom: 1px solid #112F53;
}

.flights-date{
    display: flex;
    gap: 17px;
    align-items: center;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    align-items: center;
    text-align: center;

    color: #112F53;
}

.flight-city{
    display: flex;
    gap: 11px;
}

.city{
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 19px;
color: #112F53;
}

.flight-plane{
    display: flex;
    gap: 10px;
    align-items: center;
}

.flight-plane__town{
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 19px;

color: #112F53;

}

.flights-hotel{
    display: flex;
    align-items: center;
    gap: 13px;
}

.first-flight{
    display: flex;
    align-items: center;
    gap: 6px;
    margin-top: 20px;
}

.osh_city{
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 22px;
display: flex;
align-items: center;

color: #474665;
}

.standart-flight{
    text-align: center;
}

.standart-flight img{
    height: 40px;
    width: 40px;
}

.standart{
font-style: normal;
font-weight: 400;
font-size: 16px;
line-height: 140%;
/* or 22px */
display: flex;
align-items: center;

color: #112F53;
}

.many__place{
    margin-left: 13px;
}


.book{
    margin-top: 21px;
    padding: 10px 44px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #FFFFFF;
    background: #125D98;
    border-radius: 4px;

}



.flights-more{
    display: flex;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;
    cursor: pointer;
    color: #125D98;

}

.include__in-tour{
    display: flex;
    gap: 10px;
    align-items: center;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 28px;
    display: flex;
    align-items: center;
    color: #112F53;
}

.right__hotel-img img{
    width: 462px;
    height: 308px;
}

.media__block-tours{
    margin-top: 57px;
    display: flex;
    gap: 75px;
    justify-content: center;
}

.include-tour__inside {
    margin-top: 29px;
    margin-bottom: 61px;
}

.include-tour__inside ul{
    display: flex;
    gap: 40px;


}

.about-flight__times1{
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 20px;
}

.include-tour__inside ul li{
    padding-left: 10px;
    display: flex;
    gap: 10px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    align-items: center;

    color: #112F53;
}

.about__hotels{
    display: flex;
    gap: 114px;
}

.hotel-lists{
    margin-top: 24px;
}


.hotel__name h1{
    text-align: center;
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    color: #112F53;
}

.hotel__description-book{
    margin-top: 16px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}

.hotel__description-book:not(:last-child){
    margin-bottom: 20px;
}

#more-read{
    display: none;
}

.btn-to__more{
    display: flex;
    justify-content: flex-end;
}

.btn__read-more{
    display: flex;
    align-items: center;
    gap: 9px;
    padding: 10px 26px;
    color: white;
    font-size: 16px;
    background: #125D98;
    border-radius: 4px;
    cursor: pointer;
}

.close__btn-li{
    display: flex;
    justify-content: center;
}

.close__btn-li button{
    padding: 22px 0;
    width: 100%;
}

.hidden__text ul li{
    margin-bottom: 5px;
    list-style-type: circle;
    font-weight: 300;
}

.hidden__text ul li.col-2{
    margin-top: 15px ;
}

@media (max-width:1240px){
    .inner__book{
        padding: 10px;
    }


}


@media (max-width:1160px){

    .inner__book{
        padding: 0;
    }

    .about__hotels{
        display: block;
    }

    .left__hotel{
        display: flex;
        align-items: flex-start;
        gap: 50px;
        margin-bottom: 150px;
        max-width: 100%;
    }

    .right__hotel{
        display: flex;
        align-items: flex-start;
        gap: 50px;
    }

 

}

@media (max-width:1048px){
    .media__block-tours{
        display: block;
    }
    .about__wrapper-tours{
        display: flex;
        justify-content: center;
    }
}


@media (max-width:950px){
    .left__hotel{
        display: block;
    }

    .right__hotel{
        display: block;
    }
  
    .right__hotel-img{
        display: flex;
        justify-content: center;
    }

}

@media (max-width:620px){
        .include-tour__inside ul{
            flex-wrap: wrap;
        }

}

@media (max-width:560px){
   

   .media__block-tours{
    padding:  0 0 0 50px;
   }


}

@media (max-width:325px){
   

    .media__block-tours{
     padding:  0 0 0 3jj0px;
    }
 
 
 }




@media (max-width:500px){
    .hotel__description-book{
        font-size: 14px;
        padding: 20px;
    }

    .right__hotel-img img{
        width: 400px;
        height: 280px;
    }
}


@media (max-width:440px){

    .right__hotel-img img{
        width: 320px;
        height: 200px;
    }
}

@media (max-width:390px){
        .media__block-tours{
            padding: 0 0 0 20px;
        }

        .include-tour__inside ul li{
            font-size: 14px;
        }
}


@media (max-width:360px){

    .right__hotel-img img{
        width: 288px;
        height: 192px;
    }
}

@media (max-width:358px){
    .media__block-tours{
        padding: 0;
    }
  
}

