.modalka{
    position: fixed;
    left: 0;
    right: 0;
    bottom: 0;
    top: 120px;
    background-color: rgba(0, 0, 0, 0.277);
    padding: 0px 0px;
    z-index: 99;
}
.modal_content__users{
    width: 219px;
    height: 100vh;
    background-color: white;
    padding: 20px;
}

.menu-content__users ul{
        display: block;
}

.menu-content__users a{
    display: flex;
    align-items: center;
    gap: 8px;
}

.menu-content__users  a.active{
    padding: 13px 43px 13px 46px;
    border-left: 2px solid #125D98;;
    background: #F5F8FE;
    border-radius: 6px;
    color: #125D98;
}

.menu-content__users a.active img{
    filter: invert(126%) sepia(99%) saturate(678%) hue-rotate(170deg) brightness(76%) contrast(96%);
}

.menu-content__users a svg{
    font-size: 24px;
    color: #A9A9A9;
}


.menu-content__users{
    display: flex;
    flex-direction: column;
    height: 80vh;
    gap: 50px;
    /* justify-content: space-between; */
}

.menu-content__users a:not(:last-child){
    margin-bottom: 47px;
}

.menu-content__users ul a {
    font-size:18px;
    font-style: normal;
    font-weight: 300;
    line-height: 17px;    
    color: #A9A9A9;
}

.modal-content__come-in{
    display: flex;
    justify-content: center;
    }

.modal-content__come-in a{
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 17px;    
    color: #A9A9A9;
}


.modal_none{
    display: none;
}


@media (max-width:550px){
    .menu-content__users ul a {
        font-size: 18px;
    }
    .modal-content__come-in a{
        font-size: 16px;
    }
   
}

@media (max-width:450px){
    .menu-content__users ul a {
        font-size: 17px;
    }
    .modal-content__come-in a{
        font-size: 16px;
    }

   
}

@media (max-width:380px){
    .menu-content__users ul a {
        font-size: 16px;
    }
    .modal-content__come-in a{
        font-size: 16px;
    }

}

@media (max-width:330px){
    .menu-content__users ul a {
        font-size: 14px;
    }
    .modal-content__come-in a{
        font-size: 14px;
    }

}

