.steps {
  display: flex;
  justify-content: space-between;
  padding: 40px 0;
  .circles {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .circle {
    width: 64px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 8px;

    &__item {
      position: relative;
      width: 22px;
      height: 22px;
      border-radius: 50px;
      border: 2px solid #d1d5db;

      display: flex;
      justify-content: center;
      align-items: center;

      & > .select {
        width: 8px;
        height: 8px;
        border-radius: 50px;
        background: #125d98;
      }

      & > .active {
        width: 12px;
        height: 12px;
        border-radius: 50px;
        background: #125d98;
      }

      &.select {
        border: 2px solid #125d98;
        cursor: pointer;
      }

      &.active {
        background-color: #125d98;
        border: 2px solid #125d98;
        cursor: pointer;
        &.select {
          background-color: #fff;
        }
      }

      &--left_line {
        position: absolute;
        left: -100%;
        top: 50%;
        width: 90%;
        border-bottom: #d1d5db solid 2px;
        &.border {
          border-bottom: #125d98 solid 2px;
        }
      }

      &--right_line {
        position: absolute;
        right: -100%;
        top: 50%;
        width: 90%;
        border-bottom: #d1d5db solid 2px;
        &.border {
          border-bottom: #125d98 solid 2px;
        }
      }
    }

    &__title {
      font-family: "Lato";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;

      color: #000000;
    }
  }
}
