.page-404 {
  display: flex;
  justify-content: center;

  &__content {
    display: flex;
    flex-direction: column;
    gap: 20px;
    text-align: center;
  }
  &__texts {
    display: flex;
    flex-direction: column;
    gap: 5px;
    @media (max-width:485px) {
      font-size: 14px;
    }

  }
  &__title {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 165%;
    color: #112f53;
    @media (max-width:500px){
      font-size: 22px;
    }
    
  }
  &__background img{
    width: 60%;
  }
}
