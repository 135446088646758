.question{
    background-image: url('../../assets/site-svgs/mechit.svg');
    background-position: center;
    background-size: cover;
}


.question-wrapper{
    max-width: 1116px;
    margin: 0 auto;
}



.question-wrapper__usually{
    padding: 197px 0 119px 0;
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 45px;
    color: #125D98;

}


@media (max-width:705px){
    .question-wrapper__usually{
        padding: 100px 0 0 0;
    }
}


@media (max-width:485px){
  
    .question-wrapper__usually{
        font-size: 26px;
    }
}


@media (max-width:402px){
  
    .question-wrapper__usually{
        font-size: 20px;
    }
}