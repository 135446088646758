@import './variables';
@import './libs/normalize';

@import './components/all';

body {
  background: #ffffff;
  font-family: 'Lato', sans-serif;
  font-family: 'Comfortaa', cursive;
}

html {
  scroll-behavior: smooth;
}

input {
  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}

.wrapper {
  display: flex;
  background-color: #F5F5F5;
  min-height: 100vh;
}

.main-container {
  margin-left: 250px;
  display: block;
  width: 100%;
}

.container {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 15px;
}

.page {
  min-height: calc(100vh - 120px);
  margin: 16px;
  background: #ffffff;
  border-radius: 6px;
  @media screen and (min-width: 320px) and (max-width: 430px) {
    & {
      margin: 0;
    }
  }
}

.center-msg {
  padding: 32px;
  font-size: 18px;
  color: gray;
  text-align: center;
}

.hot_deals {
  display: flex;
  align-items: center;
  height: 40px;
}

.hot_deals input {
  width: 32px;
}

.item-counter{
  justify-content: flex-end;
}
