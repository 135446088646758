.form {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  column-gap: 55px;
  align-items: flex-end;
  padding-bottom: 15px;

  &_price {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-end;
    gap: 20px;
  }

  &--item {
    display: flex;
    flex-direction: column;
    &.error {
      & {
        padding-bottom: 0px;
      }

      label {
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        color: #f76659;
      }
      input {
        border-bottom: 1px solid #f76659;
      }

      .error_text {
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        color: #f76659;
        margin-top: 2px;
      }
    }

    .added {
      background: rgba(70, 163, 236, 0.1);
      border: 1px solid #125d98;
      border-radius: 6px;

      input {
        border: none;
      }
    }

    &.submit {
      padding-top: 10px;
      padding-bottom: 0;
    }

    input {
      width: 205px;
    }
    label {
      margin-bottom: 8px;

      span {
        color: #125d98;
      }
    }
  }
}
