.modal {
  &.active {
    display: flex;
  }

  display: none;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.277);
  position: fixed;
  top: 0;
  right: 0;
  justify-content: center;
  align-items: center;
  z-index: 10;

  &__content {
    width: fit-content;
    height: fit-content;
    background-color: white;
    padding: 0 14px 0 14px;
    border-radius: 12px;
    padding: 25px;
    &::-webkit-scrollbar {
      display: none;
    }

    .flights__info{
      table {
        height: 300px;
        overflow: auto;
        display: block;
      }::-webkit-scrollbar{
        width: 7px;
        background-color: #F5F5F5; /* Set the background color of the scrollbar */
      }::-webkit-scrollbar-thumb {
        background-color: #125d98; /* Set the color of the thumb */
     }
    }

    .app__btn {
      display: flex;
      justify-content: end;
      padding-top: 20px;
    }

    .app {
      display: flex;
      justify-content: center;
      padding-top: 20px;

      &__item_title {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 165%;
        color: #474665;
      }

      &__item_text {
        padding-left: 10px;
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 165%;
        color: #000000;
      }

      &__title {
        display: flex;
        align-items: center;
        gap: 20px;
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 16px;
        color: #125d98;
      }

      &__main {
        display: flex;
        justify-content: space-between;
        gap: 20px;
      }

      &__block {
        text-align: start;
      }

      &__items {
        padding-top: 20px;
        width: 242px;
        border-bottom: 1px solid #474665;
        padding-bottom: 5px;
      }

      &__item {
        display: flex;
        flex-direction: column;
        gap: 6px;
      }
    }

    .popup_header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 1px solid #e5e9eb;
      padding: 10px;

      img {
        cursor: pointer;
      }
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    padding-bottom: 16px;
    border-bottom: 1px solid #e5e9eb;

    &--text {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 24px;
      color: #252c32;
    }

    img {
      cursor: pointer;
    }
  }

  &__body {
    height: fit-content;
    width: 100%;
    margin: 0;
    padding: 0;
  }

  &__actions {
    display: flex;
    justify-content: flex-end;

    input[type='submit'] {
      height: fit-content;
      width: fit-content;
      min-width: 120px;
      background-color: #125d98;
      border-radius: 4px;
      color: #e5e9eb;
    }
  }

  @media screen and (max-width:700px){
    &__actions {
      justify-content: center;
    }
  }

  &_blocks {
    // padding: 27px;
    padding-left: 10px;
    padding-right: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px;
    padding-top: 20px;


    &-from {
      display: flex;
      align-items: center;
      gap: 11px;
      color: #125d98;
      width: 50%;
    }
    .block1 {
      .arrow_next {
        align-items: center;
        display: flex;
        gap: 11px;

        div {
          color: #125d98;
        }
      }

      .arrow_prev {
        display: flex;
        align-items: center;
        gap: 11px;

        img {
          width: 20px;
          height: 13px;
        }

        div {
          color: #125d98;
        }
      }

      .punkt {
        padding-top: 20px;

        .flights__item {
          .add_route {
            cursor: pointer;
            width: 286px;
            height: 32px;
            border: 2px solid #125d98;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            color: #125d98;
          }

          .flights_label {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #474665;
          }

          .flights_label1 {
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 165%;
            position: relative;
            bottom: 10px;
          }

          .flights_input {
            outline: none;
            width: 100%;
            min-width: 205px;
            max-width: 328px;
            height: 20px;
            margin-right: 32px;
            margin-top: 12px;
            font-size: 16px;
            font-weight: 400;
            border: none;
            border-bottom: 1px solid #474665;
          }

          .flights_input1 {
            outline: none;
            width: 100%;
            min-width: 73px;
            max-width: 173px;
            height: 20px;
            margin-top: 12px;
            font-size: 16px;
            font-weight: 400;
            border: none;
            border-bottom: 1px solid #474665;
          }
        }

        .flights__item_error {
          .error_text {
            font-family: 'Lato', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 165%;
            color: #f76659;
          }

          .add_route {
            cursor: pointer;
            width: 286px;
            height: 32px;
            border: 2px solid #125d98;
            border-radius: 4px;
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            font-style: normal;
            font-weight: 700;
            font-size: 14px;
            line-height: 17px;
            color: #125d98;
          }

          .flights_label {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            color: #f76659;
          }

          .flights_label1 {
            font-style: normal;
            font-weight: 300;
            font-size: 16px;
            line-height: 165%;
            position: relative;
            bottom: 10px;
          }

          .flights_input {
            outline: none;
            width: 100%;
            min-width: 205px;
            max-width: 328px;
            height: 20px;
            margin-right: 32px;
            margin-top: 12px;
            font-size: 16px;
            font-weight: 400;
            border: none;
            border-bottom: 1px solid #f76659;
          }

          .flights_input1 {
            outline: none;
            width: 100%;
            min-width: 73px;
            max-width: 173px;
            height: 20px;
            margin-top: 12px;
            font-size: 16px;
            font-weight: 400;
            border: none;
            border-bottom: 1px solid #474665;
          }
        }
      }

      .buttons_popup {
        padding-top: 15px;

        .buttons_inner_popup {
          gap: 20px;
          display: flex;
          padding-left: 0px;

          .add_popup {
            width: 97px;
            height: 36px;
            background: #125d98;
            border-radius: 4px;
            font-family: 'Lato', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #ffffff;
          }

          .remove_popup {
            width: 97px;
            height: 36px;
            background: #125d98;
            border-radius: 4px;
            font-family: 'Lato', sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 19px;
            color: #ffffff;
          }
        }
      }
    }
  }

  &__describe {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.277);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    .modal_describe_content {
      width: 560px;
      height: 419px;
      background-color: white;
      border-radius: 12px;

      .notiflication_linear {
        width: 100%;
        height: 2px;
        background: #e5e9eb;
      }

      .comment {
        padding: 20px 40px 20px 32px;

        textarea {
          padding: 6px 12px;
          width: 95%;
          height: 225px;
          outline: none;
          font-family: 'Inter', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          letter-spacing: -0.006em;
          color: #6d6f70;

          &::placeholder {
            font-family: 'Inter', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.006em;
            color: #9aa6ac;
          }
        }
      }
    }
  }
}

.add_help {
  width: 200px;
  height: 36px;
  background: #125d98;
  border-radius: 4px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 19px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  color: #ffffff;
}

.footer_button {
  display: flex;
  align-items: center;
  justify-content: end;
  margin-top: 20px;
  padding-right: 10px;

  .footer_button_end {
    display: flex;
    align-items: center;
    gap: 12px;

    .cancel {
      width: 97px;
      height: 36px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background-color: #ffffff;
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #252c32;
      border: 2px solid #dde2e4;
      border-radius: 4px;
    }

    .save {
      width: 97px;
      height: 36px;
      background: #125d98;
      border-radius: 4px;
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #ffffff;
    }
  }
}
