.profile-ajy__header {
    background-image: url(assets/img/kaaba.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    height: 506px;
}

.delete-hover__image{
    position: relative;
}


.inst-time__inside img{
    width: 156px;
    height: 156px;
}

.inst-time__inside video{
    width: 156px;
    height: 156px;
}

.inst__inside__items{
    max-width: 342px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
}

table.kanat-table {
    width: 100%;
    margin-top: 24px;
    font-size: 14px;
    font-style: normal;
    border-collapse: collapse;
    text-align: center;
}

td.black-text {
    color: black;
    font-weight: 500;
}

thead.kanat__thead {
    height: 40px;
}

tr.kanat__tr td {
    font-size: 16px;
    padding: 8px 20px;
}


.profile-ajy__main {
    max-width: 1088px;
    margin: 0 auto;
}

.ajybashyprofile-wrapper {
    display: flex;
    gap: 73px;
}

@keyframes soljak {
    from {
        transform: translateX(100px);
    }

    to {
        transform: translateX(0px);

    }
}

.main__right-side {
    animation: soljak 2s ease-in-out both;
}

.left-side__bars {
    position: relative;
    bottom: 90px;
}

.image_icon-ajy img {
    border-radius: 100px;
    width: 160px;
    height: 160px;
}



.left-side__bars ul {
    margin-top: 26px;
}

@keyframes allLis {
    from {
        transform: translateX(-100px);
    }

    to {
        transform: translateX(0px);

    }
}


.left-side__bars ul li {
    animation: allLis 1s ease-in-out both;
    display: flex;
    gap: 23px;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 33px;
    display: flex;
    align-items: center;
    color: #474665;
    margin-bottom: 20px;
}

.inst-title {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 33px;
    color: #000000;
}




.inst-photos {
    display: flex;
    gap: 20px;
}

.inst-photos img {
    border-radius: 10px;
    margin-top: 20px;
}

.btnToShowProfile {
    margin-top: 16px;
    display: flex;
    justify-content: center;
}

.btnToShowProfile button a {
    background: #125D98;
    border-radius: 4px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
}

.btnToShowProfile button  {
   transition: 0.4s ease-in-out;
}

table.kanat-table{
    overflow: hidden;
}

tr.KANAT__TOP{
    transition: 0.5s ease-in-out;
    cursor: pointer;
}

tr.KANAT__TOP:hover{
    transform: scale(1.03);
}

.btnToShowProfile button:hover {
    transform: scale(1.05);
}


.experiense {
    margin-bottom: 16px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    color: #474665;
}

.experiense-description {
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 26px;
    color: #000000;
}



.about-experiense {
    max-width: 605px;
    margin-top: 40px;
    border-bottom: 2px solid #474665;
    padding-bottom: 16px;

}

.reis-title {
    margin-top: 32px;
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 33px;
    color: #000000;
}


.gallery__time {
    margin-top: 38px;
}

.gallery__title {
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 33px;
    display: flex;
    align-items: center;
    color: #000000;
}


.gallery__images {
    display: flex;
    justify-content: start;
}


.images__inside-this__gallery {
    display: flex;
    justify-content: center;
    gap: 44px;
}




.pics {
    position: relative;
    cursor: pointer;
    overflow: hidden;
}


img.item__image__gallery {
    width: 239px;
    height: 239px;
    object-fit: cover;
}



.pics img:hover {
    transform: scale(1.07);
    transition: all 0.3s ease-out;
}

.overlay {
    position: absolute;
    top: 0px;
    width: 100%;
    height: 99%;
    opacity: 0;
    bottom: 110px;
    transition: opacity 0.4s ease-in-out;
    background-color: rgba(0, 0, 0, .6);
}

.pics:hover .overlay {
    opacity: 1;
}

.hover {
    position: absolute;
    text-align: center;
    color: white;
    height: 100%;
    left: 50px;
    top: 100px;
}

.hover h4 {
    display: flex;
    align-items: center;
    gap: 10px;
}


.delete-hover__image{
    width: 100%;
    display: none;
    position: absolute;
    left: 0;
    bottom: 0;
}

.delete-hover__image button{
    width: 100%;
}




.model {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, .9);
    transition: opacity .4s ease, visibility .4s easy, transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
}

.model.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);

}

.appears img {
    width: 700px;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;

    margin: 0 auto;
}

.closed__gallery img {
    position: fixed;
    top: 10px;
    right: 15px;
    width: 2rem;
    height: 2rem;
    padding: 5px;
    color: #ffff;
    font-size: 60px;
    cursor: pointer;
}


.btnToSeeMore {
    margin-top: 64px;
    display: flex;
    justify-content: center;
}


.btnToSeeMore button {
    padding: 16px 13px;
    background: #125D98;
    border-radius: 2px;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;

    color: #FFFFFF;

}





@media (max-width:1085px) {


    .images__inside-this__gallery {
        flex-wrap: wrap;
        justify-content: center;
    }

    .gallery__title {
        justify-content: center;
        margin-bottom: 50px;
    }
}


@media (max-width:860px) {
    .ajybashyprofile-wrapper {
        display: block;
    }

    .main__left-side {
        display: flex;
        justify-content: space-between;
    }

    .left-side__bars {
        position: relative;
        bottom: 0;
    }
}


@media (max-width:680px) {

    .overlay{
        display: none;

    }

    .delete-hover__image{
        display: inline;
    }

    .pop-up__image img {
        width: 95%;
    }

    .main__left-side {
        display: block;
    }

    tr.kanat__tr td {
        padding: 8px 20px 8px 0;
    }

    .left-side__childs {
        display: flex;
        justify-content: center;
        position: relative;
        top: -50px;
    }

    .insagramm__time {
        display: flex;
        justify-content: center;
    }
}

@media (max-width:460px) {
    .experiense-description {
        font-size: 14px;
    }

    .profile-ajy__header {
        /* background-image: url('../../../../../assets/imgs/image\ 26.png');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat; */
        height: 350px;
    }


    .left-side__bars ul li:not(:first-child) {
        font-size: 17px;
    }

    table tr {
        font-size: 14px;
    }

    .gallery__time span {
        font-size: 45px;
        top: 15px;
    }

}

@media (max-width:392px) {
    tr.kanat__tr td {
        padding: 5px 0;
    }

    td {
        padding: 5px 0;
    }
}

@media (max-width:360px) {
    .inst-photos {
        flex-direction: column;
    }

    .left-side__bars ul li:not(:first-child) {
        font-size: 15px;
    }

    .gallery__time span {
        font-size: 42px;
        top: 20px;
    }
}