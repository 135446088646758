.choose_clicked {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #112f53;
  gap: 13px;
}

.create_home {
  padding: 33px 32px 33px 32px;

  .hostels {
    input[name='title'] {
      width: calc(100% - 20px);
      height: 40px;
      background: #f7fafc;
      box-sizing: border-box;
      padding: 0px 16px;
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 100%;
      color: #112f53;
      margin-bottom: 40px;

      &.error {
        background-color: rgba(247, 102, 89, 0.12);
      }
    }

    &__row {
      width: 100%;
      margin-bottom: 40px;
      background: #f7fafc;
      border-bottom: 1px solid #125d98;
      border-radius: 4px 4px 0px 0px;

      &.completed {
        background: #f1fbf4;
        border-bottom: 1px solid #00b633;
        border-radius: 4px 4px 0px 0px;
      }

      &.error {
        background: rgba(247, 102, 89, 0.12);
        border-bottom: 1px solid #f76659;
        border-radius: 4px 4px 0px 0px;
      }

      .title_choose {
        display: flex;
        align-items: center;
        gap: 19px;

        .title {
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 100%;
          color: #112f53;
        }
      }

      .choose__options {
        display: flex;
        flex-wrap: wrap;
        // gap: 36px;
        padding-top: 33px;

        .main__option {
          .first_option_border {
            cursor: pointer;

            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 190px;
            height: 24px;
            background: rgba(70, 163, 236, 0.1);
            border: 1px solid #125d98;
            border-radius: 6px 6px 0px 0px;
            padding: 8px 10px;

            .first_option_text {
              font-family: 'Lato', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              color: #252c32;
            }
          }

          .first_option {
            cursor: pointer;

            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 190px;
            height: 24px;
            background: rgba(70, 163, 236, 0.1);
            border: 1px solid #125d98;
            border-radius: 6px;
            padding: 8px 10px;

            .first_option_text {
              font-family: 'Lato', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              color: #252c32;
            }
          }

          .first_option_clicked {
            width: 210px;
            position: absolute;
            z-index: 1;
          }
        }

        .second_option_border {
          cursor: pointer;

          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 190px;
          height: 24px;
          background: rgba(70, 163, 236, 0.1);
          border: 1px solid #125d98;
          border-radius: 6px 6px 0px 0px;
          padding: 8px 10px;

          .first_option_text {
            font-family: 'Lato', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            color: #252c32;
          }
        }

        .second_option {
          cursor: pointer;

          display: flex;
          justify-content: space-between;
          align-items: center;
          width: 190px;
          height: 24px;
          background: rgba(70, 163, 236, 0.1);
          border: 1px solid #125d98;
          border-radius: 6px;
          padding: 8px 10px;

          .first_option_text {
            font-family: 'Lato', sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 13px;
            color: #252c32;
          }
        }

        .main__option {
          .Select {
            width: 250px;
          }

          .choose_calendar {
            cursor: pointer;
            width: 264px;
            height: 296px;
            position: absolute;
            margin-left: 10px;
          }

          .third_option {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 262px;
            height: 24px;
            background: rgba(70, 163, 236, 0.1);
            border: 1px solid #125d98;
            border-radius: 6px;
            padding: 8px 10px;

            .first_option_text {
              font-family: 'Lato', sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 13px;
              color: #252c32;
            }
          }
        }
      }

      .choose {
        padding-top: 32px;
        display: flex;
        flex-wrap: wrap;
        gap: 32px;

        .days-input {
          display: flex;
          align-items: end;
          gap: 20px;

          &-count {
            padding: 8px 16px;
            background: rgba(70, 163, 236, 0.1);
            border: 1px solid #125d98;
            border-radius: 6px;
            display: flex;
            align-items: center;
            gap: 20px;

            img {
              cursor: pointer;
              width: 20px;
              height: 20px;
            }

            &__title {
              font-family: 'Lato', sans-serif;
              font-style: normal;
              font-weight: 600;
              font-size: 16px;
              line-height: 24px;
              color: #125d98;
            }
          }

          button {
            width: 161px;
            height: 36px;
            font-size: 16px;
            font-weight: 700;
          }

          .choose_item {
            margin-right: 20px;

            label {
              font-style: normal;
              font-weight: 400;
              font-size: 16px;
              color: #474665;
            }

            input {
              background: #f7fafc;
              outline: none;
              width: 100%;
              max-width: 288px;
              height: 20px;
              margin-top: 12px;
              font-size: 16px;
              font-weight: 400;
              border: none;
              border-bottom: 1px solid #474665;
            }
          }
        }
      }
    }

    &.choose {
      width: 70%;
    }

    &.excursions {
      width: 100%;
      max-width: 470px;
    }

    &.services {
      width: 100%;
      max-width: 470px;
    }

    .total {
      display: flex;
      padding-top: 40px;
      flex-wrap: wrap;
      justify-content: start;
      gap: 45px;

      .calculator {
        &.type {
          display: flex;
          align-items: center;
          gap: 10px;
          background: #f7fafc;
          margin: 0;
        }

        &__type {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 48px;
          line-height: 58px;
          display: flex;
          align-items: center;
          text-align: center;
          color: rgba(18, 93, 152, 0.8);
        }

        &__title {
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          color: #112f53;

          @media (max-width: 500px) {
            font-size: 16px;
          }
        }

        &-block {
          margin-top: 15px;
          padding: 15px 35px 16px 15px;
          background: #f7fafc;
          border-radius: 4px;
          padding-top: 8px;
        }

        &__text {
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #112f53;

          @media (max-width: 500px) {
            font-size: 14px;
          }
        }

        &__price {
          padding-top: 16px;
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          color: #125d98;
        }
      }
    }

    .status {
      padding-top: 40px;
      gap: 45px;

      .block {
        display: flex;
        padding-top: 20px;
        align-items: flex-start;
        justify-content: start;
        flex-wrap: wrap;
        gap: 45px;
        padding-bottom: 20px;
      }

      .calculator {
        cursor: pointer;

        &.type {
          min-width: 176px;
          min-height: 63px;
          display: flex;
          padding-left: 16px;
          justify-content: center;
          align-items: center;
          background: #f7fafc;
          margin: 0;
        }

        &--block {
          min-width: 176px;
          min-height: 63px;
          padding: 8px 16px 8px 0px;
          background: #f7fafc;
          border-radius: 4px;
          display: flex;
          align-items: center;
          justify-content: center;
          flex-direction: column;

          &.status:hover {
            background: rgb(191, 225, 255);
          }
        }

        &__type {
          font-family: 'Lato';
          font-style: normal;
          font-weight: 400;
          font-size: 48px;
          line-height: 58px;
          display: flex;
          align-items: center;
          text-align: center;
          color: rgba(18, 93, 152, 0.8);
        }

        &__title {
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 20px;
          line-height: 24px;
          color: #112f53;
        }

        &__text {
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 16px;
          line-height: 19px;
          color: #112f53;
        }

        &__price {
          padding-top: 16px;
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 24px;
          color: #125d98;
        }
      }
    }

    &__block {
      cursor: pointer;
      padding: 13px;

      display: flex;
      align-items: center;
      justify-content: space-between;

      &__img__text {
        display: flex;
        align-items: center;
        gap: 8px;

        .hostels_text {
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 100%;
          color: #112f53;

          @media (max-width: 500px) {
            font-size: 16px;
          }

          @media (max-width: 345px) {
            font-size: 14px;
          }
        }
      }

      .hostel__count {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;
        color: #112f53;

        @media (max-width: 500px) {
          font-size: 14px;
        }

        @media (max-width: 340px) {
          font-size: 12px;
        }
      }
    }

    &__select {
      padding: 18px;
      .select__items {
        padding-left: 23px;
        padding-top: 8px;
      }
    }

    .hotel__block {
      padding-top: 43px;

      &__titles {
        margin-left: 23px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        @media (max-width: 500px) {
          margin-left: 0;
        }

        &__title {
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          color: #000000;

          @media (max-width: 500px) {
            font-size: 15px;
          }

          @media (max-width: 400px) {
            font-size: 14px;
          }
        }

        &__day-title {
          font-family: 'Lato', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 24px;
          text-align: center;
          color: #000000;

          @media (max-width: 500px) {
            font-size: 15px;
            margin-left: 0;
          }

          @media (max-width: 400px) {
            font-size: 14px;
          }
        }
      }
    }
  }
}

.confirmation_btn {
  width: calc(100% - 46px);
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  padding: 0 0 32px 32px;
  flex-wrap: wrap;
  row-gap: 10px;

  


  button {
    padding: 14px 25px;
    @media (max-width: 330px) {
      padding: 10px 15px;
    }
  }
}

.none {
  display: none;
}

.item {
  display: flex;
  gap: 40px;
  align-items: center;
  padding: 16px 8px 16px 23px;

  &__counter {
    display: flex;
    align-items: center;

    &__minus {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background: #f6f8f9;
      border: 1px solid #dde2e4;
      border-radius: 6px 0px 0px 6px;
    }

    &__count--number {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 62px;
      height: 40px;
      background: #ffffff;
      border: 1px solid #dde2e4;
    }

    &__plus {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 40px;
      height: 40px;
      background: #f6f8f9;
      border: 1px solid #dde2e4;
      border-radius: 0px 6px 6px 0px;
    }
  }

  &-block {
    width: 100%;
    border: 1px solid #125d98;
    border-radius: 4px;
    padding: 0px 26px;
    height: 68px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &__checkbox {
      width: 20px;
      height: 20px;
      cursor: pointer;
    }

    &__title {
      display: flex;
      align-items: center;
      gap: 8px;

      &__text {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 19px;

        color: #112f53;
      }
    }
  }
}

.secondSelect {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 28px 0px 22px;
  width: 100%;
  max-width: 212px;
  height: 56px;
  border: 1px solid #125d98;
  border-radius: 4px;
  margin-top: 16px;
  margin-bottom: 12px;

  &__title {
    display: flex;
    align-items: center;
    gap: 12px;
  }

  &__checkbox {
    width: 20px;
    height: 20px;
    cursor: pointer;
  }
}

.thirdSelect {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 28px 0px 22px;
  max-width: 400px;
  height: 56px;
  border: 1px solid #125d98;
  border-radius: 4px;
  margin-top: 16px;
  margin-bottom: 12px;
}

.none {
  display: none;
}

.added {
  width: 100%;
  max-width: 179px;
  border: 2px solid #125d98;
  border-radius: 12px;
}

.blocks {
  display: flex;
  gap: 40px;

  .block {
    &__items {
      .thirdSelect {
        min-width: 240px;
      }
    }
  }
}


.ql-editor.ql-blank{
  height: 100px !important;
}