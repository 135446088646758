.directory {
  &__title {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 24px;
    color: #000000;
    padding-bottom: 32px;
    padding-top: 25px;
  }
}
.res_list {
  display: none;
}
.main_prossent {
  display: flex;
  align-items: center;
  justify-content: center;

  .twice {
    display: flex;
    align-items: center;
    gap: 8px;
    cursor: pointer;

    .prossent {
      align-items: center;
      display: flex;
      width: 115px;
      background: #ffffff;
      border: 1px solid #125d98;
      border-radius: 4px;
      height: 40px;

      .left {
        width: 30%;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgba(0, 182, 51, 0.4);
        color: #ffffff;
      }

      .yet {
        width: 70%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #125d98;
      }
    }

    .answer {
      color: #112f53;
    }
  }
}
.excursion {
  &_res_list {
    display: none;
  }
  &__inner {
    padding: 10px 22px;
  }

  &__data {
    padding-top: 30px;
    padding-left: 30px;
    padding-bottom: 40px;

    label {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 165%;
      color: #4a5568;
    }
  }
}

.hotels {
  padding: 0 32px 32px;
}
.hotels__form {
  margin-bottom: 46px;
}
.actions__save-btn {
  margin-top: 40px;
}

.save-hotel__icon {
  cursor: pointer;
  padding: 10px 15px;
  background: #125d98;
  border-radius: 2px;
  font-family: 'Lato', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 17px;
  color: #ffffff;
}
.actions__flex {
  display: flex;
  width: 100%;
  align-items: center;
  gap: 9px;
}

.actions {
  width: 80px;

  div {
    // width: 100%;
  }
  &_left {
    width: 80px;
    padding-left: 30px;

    div {
      width: 100%;
      display: flex;
      justify-content: space-between;
    }
  }
}
.actions__block {
  width: 100%;
  gap: 20px;
  display: flex;
}
.actions__none {
  display: none;
}

.editFlight{
  padding: 30px;
  .flight__list_item{
    display: flex;
    align-items: start;
    flex-wrap: wrap;
    gap: 50px;
    width: 100%;
    max-width: 800px;
  }
  .flight_text{
    padding-bottom: 15px;
  }
}


.flights {
  padding: 0 32px 10px;

  &__info{
    padding: 0px 30px 30px 30px;
  }
 

  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 24px;
  }

  &__table {
    font-size: 16px;
    font-style: normal;
    border-collapse: collapse;
    text-align: center;

    thead {
      height: 40px;
      background-color: #edf2f7;

      td {
        font-weight: 400;
        color: #4a5568;
        padding-left: 20px;
      }
    }


    tbody {
      tr {
        padding: 0px 10px;

        td {
          font-weight: 500;
          color: #718096;
          flex: 1 1 330px;
          // border-bottom: 1px solid #e2e8f0;
        }

        &:hover {
          background: rgb(220, 236, 252);
        }
      }
    }
  }
}

.ResList {
  padding: 0px 15px;
  .res {
    width: 96%;
    padding: 20px 0px 20px 0px;
    border-bottom: 1px solid #e2e8f0;

    &_list {
      display: none;
    }

    &_block {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-start;
      justify-content: space-between;
      gap: 20px;
      padding-top: 10px;
    }
    &_item {
      display: flex;
      flex-direction: column;
      gap: 5px;
      .control_btns {
        display: flex;
        gap: 10px;
      }
    }
    &_title {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #112f53;
    }
    &_text {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 17px;
      display: flex;
      align-items: center;

      color: #718096;
    }
    &_id {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      display: flex;
      align-items: center;
      color: #718096;
    }
  }
}

.airlines {
  padding: 0px 30px 30px 30px;

  &_form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
}

.form--checkbox input{
  width: 32px;
}

.checked{
  background: #125d98;
}

.closed{
  width: 10px;
}