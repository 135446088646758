.home {
  &__title {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;
    padding-left: 15px;
  }

  form {
    width: calc(100% - 49px);
    display: flex;
    flex-wrap: wrap;
    gap: 32px;
    margin-left: 49px;

    div {
      margin-top: 40px;
    }

    label {
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      color: #474665;
    }

    input {
      outline: none;
      width: 100%;
      min-width: 228px;
      max-width: 328px;
      height: 20px;
      margin-right: 32px;
      margin-top: 12px;
      font-size: 16px;
      font-weight: 400;
      border: none;
      border-bottom: 1px solid #474665;
    }

    input:focus {
      outline: none;
    }

    button {
      img {
        margin-left: 20px;
      }
    }
  }

  table {
    width: calc(100% - 110px);
    height: 453px;
    margin-top: 10px;
    margin-left: 49px;
    font-size: 16px;
    font-style: normal;
    border-collapse: collapse;
    text-align: center;

    thead {
      height: 40px;
      background-color: #edf2f7;

      td {
        font-weight: 400;
        color: #4a5568;
        padding-left: 20px;
      }
    }

    tbody {
      position: relative;

      .both_img_none {
        display: none;
      }

      .both_img {
        position: absolute;
        left: 64px;

        .both_images {
          display: flex;
          padding-top: 15px;
          gap: 9px;

          img {
            cursor: pointer;
          }
        }
      }

      tr {
        td {
          font-weight: 500;
          color: #718096;
          padding-left: 20px;
          
          border-bottom: 1px solid #e2e8f0;
        }

        &:hover {
          background: rgba(70, 163, 236, 0.2);
        }
      }
    }
  }
}

.block {
  &__center {
    width: 100%;
    height: calc(100vh - 115px);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 45px;

    &--info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 12px;
    }

    &--title {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 700;
      font-size: 22px;
      line-height: 26px;
      color: #3a3a3a;
    }

    &--desc {
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 400;
      font-size: 11px;
      line-height: 13px;
      text-align: center;
      color: #3a3a3a;
    }
  }
}

.info_describe-text {
  display: none;
  color: #000000;
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 150%;
  margin-right: 10px;
}
.route {
    cursor: pointer;
}
.route:hover {
  color:#125d98;
}
.info_describe-text__flex{
  display: none;
}

.application {
  padding: 20px;

  &_res_list {
    display: none;
  }

  &__form {
    display: flex;
    flex-direction: column;
    row-gap: 35px;
    padding: 15px;
    .info {
      &_users {
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        gap: 40px;
      }
      &_block1 {
        width: 30%;
      }
      &_block2 {
        width: 60%;
      }
      &_number {
        font-size: 18px;
      }
     
      
    }
    button {
      padding: 16px 18px;
      height: auto;
      min-width: fit-content;
    }
    button a {
      color: #fff;
    }
  }

  &__price {
    padding: 10px 22px;
    margin-bottom: 20px;
    padding-bottom: 20px;

    &__number {
      display: flex;
    }

    .title {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 20px;
      line-height: 165%;
      color: black;
    }

    form {
      display: flex;
      padding-top: 29px;
      padding-left: 22px;
      align-items: flex-end;
      gap: 30px;

      label {
        color: #4a5568;
      }

      input {
        width: 240px;
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
      button {
        width: 161px;
        height: 36px;
      }
    }
  }
}

.reset {
  &__container {
    height: calc(100vh - 32px);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 20px;
    padding-bottom: 32px;
  }

  .error {
    color: red;
  }

  &__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: 21px;
    button {
      height: auto;
      min-width: fit-content;
    }
    input {
      width: 100%;
      height: 40px;
      padding: 0 10px;
    }
  }
}
