.profile {
  padding: 26px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  max-height: fit-content;
  gap: 16px;
  min-height: calc(100vh - 164px);



  .editAvatar-block {
    .media_avatar {
      padding: 5px;
      margin-top: 10px;
      width: 157px;
      height: 25px;
      align-items: center;
      justify-content: center;
      display: none;
      gap: 10px;
      border-radius: 4px;
      background: #125D98;
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 17px;
      color: #fff;

      @media (max-width: 890px) {
        .editAvatar-block {
          .media_avatar {
            display: none;
          }
        }
      }
    }
  }


  .description__block {
    display: flex;
    align-items: flex-end;
    gap: 20px;
    padding-top: 50px;

    &_delete {
      height: 40px;
      background: red;
      border-radius: 4px;
      font-weight: 500;
      font-size: 16px;
      color: #ffffff;
      border: none;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 20px;
    }

    &__twice {
      display: flex;
      flex-direction: column;
      gap: 20px;
      width: 61%;
    }

    &__buttons {
      display: flex;
      align-items: flex-end;
      gap: 10px;
    }
  }

  .radio {
    &_twice {
      text-align: center;
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    &_number {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 48px;
      line-height: 58px;
      color: rgba(18, 93, 152, 0.8);
    }

    &_items {
      display: flex;
      flex-direction: column;
      gap: 25px;
    }

    &_item {
      cursor: pointer;
      width: 212px;
      height: 68px;
      display: flex;
      align-items: center;
      gap: 10px;
      justify-content: center;
      background: #f5f8fe;
      border-radius: 4px;

      input {
        cursor: pointer;
      }
    }

    &_text {
      cursor: pointer;
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
    }

    &_price {
      cursor: pointer;
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #125d98;
    }
  }

  &-info__right {
    display: flex;
    justify-content: space-between;
    padding: 0px 40px;

    div {

      button {
        margin-top: 15px;
      }
    }
  }

  &__raises {
    width: 60%;
  }

  

  &_avatar {
    width: 160px;
    height: 160px;
    border-radius: 100%;
  }

  &Edits {
    padding: 20px;
  }

  .editAvatar {
    width: 160px;

    &-block {
      position: relative;
      width: 160px;
      height: 160px;
      cursor: pointer;

      .editModal {
        position: absolute;
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;

        &:hover {
          background: rgba(18, 93, 152, 0.5);
          opacity: 0.9;
          border-radius: 50%;
        }
      }
    }

    &-Icon {
      width: 160px;
      height: 160px;
      border-radius: 50%;
    }
  }

  .form--item {
    padding-top: 28px;

    &__title {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 165%;
      color: #474665;
    }

    &.error--profile {
      label {
        color: red;
      }

      input {
        border-bottom: 1px solid #ff0000;
      }
    }

    &__input {
      min-width: 328px;
      border: none;
      border-bottom: 1px solid #474665;
    }
  }

  &__title {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: #112f53;

    @media (max-width:1100px) {
      margin-top: 20px;

    }
  }

  &-change {
    padding-top: 26px;

    &__img {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      flex-wrap: wrap;
      gap:50px;


      img {
        cursor: pointer;
      }
    }

    &__form {
      padding-top: 32px;

      .add_describe_form {
        padding-top: 40px;
        display: flex;
        align-items: flex-end;
        gap: 25px;

        textarea {
          margin-top: 20px;
        }

        &_input {
          padding: 8px;
          width: 100%;
          outline: none;
          height: 20px;
          font-size: 16px;
          font-weight: 400;
          border: none;
          color: hsl(0deg, 0%, 20%);
          border-bottom: 1px solid #474665;
          background: initial;
        }

        &_error {
          border-color: red;

          &::placeholder {
            color: red;
          }
        }

        &_block {
          width: 61%;
        }
      }

      &__small-inputs {
        display: flex;
        flex-wrap: wrap;
        max-width: 730px;
        column-gap: 30px;
        align-items: center;
      }

      &__big-inputs {
        padding-top: 30px;

        .form--item__715__input {
          padding-top: 30px;
          min-width: 715px;
        }
      }
    }
  }

  &__save {
    display: flex;
    justify-content: space-between;
    padding: 40px 15px;
    align-items: flex-end;
  }

  &__inform {
    display: flex;
    align-items: center;
    gap: 16px;

    @media (max-width: 660px) {
      margin-bottom: 10px;
    }
  }

  &__about {
    display: grid;
    row-gap: 16px;
    padding-top: 40px;

    &__title {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 165%;
      color: #474665;
    }

    &__text {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 165%;
      color: #000000;
      // width: 100%;
      padding-bottom: 16px;
      border-bottom: 2px solid #474665;
    }
  }

  &__button {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding: 20px 20px;
  }

  .comments-for-profile {
    padding-left: 27px;
  }

  &Show {
    padding: 20px 20px;
    font-family: "Lato";
    font-style: normal;
    font-weight: 500;
    // font-size: 20px;
    line-height: 165%;
    color: #474665;

    .profile-show {
      display: flex;
      align-items: center;
      padding-top: 26px;
      gap: 35px;

      @media (max-width: 472px) {
        flex-wrap: wrap;
        justify-content: center;
      }

      @media (max-width: 660px) {
        justify-content: start;
      }

      &__block {
        display: grid;
        justify-content: center;
        row-gap: 20px;

        @media (max-width: 660px) {
          display: flex;
          margin-bottom: 10px;
          flex-direction: column;
          gap: 20px;

        }
      }

      &__blocks {
        display: flex;
        align-items: center;
        gap: 28px;

        @media (max-width: 660px) {
          display: block;
        }
      }
    }
  }

  .twice_inputs {
    display: flex;
    align-items: flex-end;
    gap: 15px;
  }
}

.login {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
    max-width: 400px;
    padding: 20px;
    border: 1px solid #474665;
    border-radius: 10px;
    background-color: #ffffff;

    &__title {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 20px;
      line-height: 24px;
      color: #112f53;
    }

    &__input {
      width: 100%;
      min-width: 328px;
      border: none;
      border-bottom: 1px solid #474665;
    }

    &__button {
      width: 100%;
      min-width: 328px;
      height: 40px;
      background: #112f53;
      border-radius: 10px;
      border: none;
      color: #ffffff;
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 165%;
    }
  }
}

.profileAji {
  &-info__block {
    padding: 20px;
  }


  &__warning {
    display: flex;
    justify-content: center;
    padding-top: 10px;
  }

  &__add--btn2 {
    cursor: pointer;
    width: 153px;
    height: 51px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #125d98;
    border-radius: 4px;
    font-weight: 500;
    font-size: 16px;
    border: none;

    color: #ffffff;
  }

  &__change--btn {
    display: flex;
    align-items: center;
    justify-content: end;
  }

  &__background {
    background: url(../../assets/img/kaaba.jpg) center,
      no-repeat;
    height: 253px;
    background-size: cover;
    width: 100%;
  }

  &-info1 {
    margin: 0 auto;
    width: 100%;
  }

  &__left {
    position: relative;
    bottom: 85px;
  }

  &__right {
    .profile__about {
      padding-left: 0px;

      .profile__about__text {
        width: 100%;
      }

      .profile__about__text {
        width: 91%;
        padding-bottom: 16px;
        border-bottom: 2px solid #474665;
      }
    }
  }

  &-fullinforms {
    padding-top: 9px;
    width: 270px;

    .profileAji__add--btn {
      width: 203px;
      height: 68px;
    }
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 13px;
    margin-top: 20px;
  }

  &__title {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 165%;
    color: #474665;

    span {
      font-weight: 500;
      font-size: 20px;
    }
  }

  &__category--add {
    margin-top: 24px;
  }

  &__add--btn2 {
    width: 203px;
    height: 68px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__add--btn {
    cursor: pointer;
    width: 220px;
    height: 220px;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px dashed #125d98;
    background-color: #ffffff;
    border-radius: 4px;
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #125d98;
  }

  &__instagram--photos {
    padding-top: 26px;

    .instagram__photos {

      &_block {
        padding-top: 16px;
        gap: 20px;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        max-width: 332px;
      }
    }

    .instagram__btn {
      display: flex;
      text-align: center;
      padding-top: 16px;
      justify-content: center;

      button {
        height: 51px;
      }
    }
  }

  .rais_left {
    padding-left: 46px;
  }

  &__raises {
    padding-top: 68px;
    width: 100%;
    padding-top: 68px;
    max-width: 775px;


    .sametable {
      width: 100%;
    }
  }

  &__change--btn {
    text-align: end;
    padding-top: 72px;
    padding-bottom: 28px;
    padding-right: 28px;

    button {
      width: 153px;
      height: 43px;
    }
  }

  &__modal--none {
    display: none;
  }

  &__modal {
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.277);
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &__content {
      width: 100%;
      max-width: 349px;
      display: flex;
      background-color: white;
      border-radius: 12px;
      flex-direction: column;
      gap: 50px;
      padding-bottom: 50px;
      margin: 0 20px;

      .profileAji__categories {
        display: flex;
        justify-content: center;

        &__item {
          .margin {
            width: 212px;
            height: 68px;
            background: #f5f8fe;
            border-radius: 4px;
            margin-top: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 6px;

            &__number {
              font-family: "Lato", sans-serif;
              font-style: normal;
              font-weight: 400;
              font-size: 48px;
              line-height: 58px;
              color: rgba(18, 93, 152, 0.8);
            }

            &__price {
              text-align: center;

              &__title {
                font-family: "Lato", sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #112f53;
              }

              &__price--number {
                font-family: "Lato", sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 19px;
                color: #125d98;
                margin-top: 4px4;
              }
            }
          }
        }
      }

      .modal__save--btn {
        border-top: 1px solid #e5e9eb;
        margin-top: 24px;
        padding-top: 24px;
        display: flex;
        justify-content: flex-end;
        padding: 20px;
        align-items: center;

        button {
          width: 120px;
          height: 36px;
        }
      }
    }
  }

  &Edit {
    padding: 0px 24px;

    .form {
      column-gap: 24px;
    }

    .profileAjiEdit-inputs {
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      gap: 60px;
    }

    .profileAji-info__right {
      display: flex;
      justify-content: space-between;
      width: 100%;
      padding-top: 40px;
    }

    .profileAji__raises {
      padding-top: 0px;
    }

    button {
      width: 181px;
      height: 40px;
    }

    .form--item {
      padding-top: 28px;

      .form--item__715__input {
        width: 100%;
        max-width: 715px;
        min-width: 600px;
      }

      label {
        padding-bottom: 0px;
      }

      input {
        padding: 2px;
        width: 320px;
      }
    }

    &__add--btn {
      background-color: #ffffff;
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 19px;
      opacity: 0.8;
      border: 2px dashed #125d98;
      border-radius: 4px;
      color: #125d98;
    }

    &-edit--form {
      padding: 23px;
    }

    .gallery {
      .profileAji__add--btn {
        cursor: pointer;
        width: 220px;
        height: 220px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      label {
        cursor: pointer;
      }

      &__added_images {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        gap: 40px;
      }

      &__photos {
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        justify-content: start;
        gap: 35px;
        padding-top: 20px;

        img {
          width: 239px;
          height: 239px;
        }
      }

      .addBtnImg {
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 35px;

        img {
          width: 239px;
          height: 239px;
        }
      }

      &__btn {
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 44px;
        gap: 32px;

        .addImageBtn {
          background: #125d98;
          border-radius: 2px;
          font-family: "Lato";
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #ffffff;
          width: 72px;
          height: 19px;
        }

        button {
          width: 190px;
          font-size: 16px;
          font-weight: 300;
          height: 51px;
        }
      }
    }

    .profileAji__raises {

      width: 63%;
    }
  }

  &__blocksflex {
    display: flex;
    justify-content: space-between;
    gap: 56px;
  }

  &-info__left {
    position: relative;
    bottom: 85px;

    .profileAji__icon {
      img {
        width: 160px;
        height: 160px;
        border-radius: 50%;
      }
    }
  }

  .profile__about__textaji {
    font-family: "Lato", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 165%;
    color: #000000;
    padding-bottom: 16px;
    border-bottom: 2px solid #474665;
  }

  &__right {
    width: 680px;

    .profile__about {
      padding-left: 0px;

      .profile__about__text {
        width: 100%;
      }

      .profile__about__text {
        width: 91%;
        padding-bottom: 16px;
        border-bottom: 2px solid #474665;
      }
    }
  }
}

.gallery {
  &__item {
    position: relative;
  }

  &_modal {
    position: relative;
  }

  &_modal_block {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.277);
  }

  &__block {
    display: block;
    cursor: pointer;
    width: 30px;
    height: 30px;
  }

  &__none {
    display: none;
  }

  &__close {
    display: flex;
    justify-content: flex-end;
  }

  &__img {
    object-fit: cover;
    width: 239px;
    height: 239px;
  }

  &__photos {
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    padding: 35px;
    justify-content: start;

    .profileAji__add--btn {
      width: 239px;
      height: 239px;
    }
  }

  &__btn {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 44px;

    label {
      width: 152px;
      height: 51px;
    }
  }
}