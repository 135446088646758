table {
  width: 100%;
  margin-top: 20px;
  font-size: 15px;
  font-style: normal;
  border-collapse: collapse;
  text-align: center;

  thead {
    height: 40px;
    background-color: #edf2f7;

    td {
      padding: 8px 6px;
      font-weight: 500;
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 140%;
      color: #4a5568;
      font-size: 16px;
    }
  }
  .setactive {
    background: #10b1470f;
  }

  tbody {
    .SelectCenter {
      display: flex;
      justify-content: center;
    }
    tr {
      height: min(80px, 10vw);
      border-bottom: 1px solid #e2e8f0;

      .blacktext {
        font-family: "Lato", sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 165%;
        color: #000000;
      }
      a {
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;
        color: #125d98;
        border-bottom: 1px solid #125d98;
      }

      td {
        font-family: "Lato", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 150%;
        color: #718096;
      }

      &:hover {
        background: rgba(70, 163, 236, 0.2);
      }
    }
  }

  .places {
    display: flex;
    align-items: center;
    gap: 10px;

    &__count {
      display: flex;
      align-items: center;
      width: 80px;
      height: 39px;
      border: 1.5px solid #125d98;
      border-radius: 4px;

      .reserved {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 30%;
        height: 100%;
        background: rgba(0, 182, 51, 0.4);
      }
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;

      color: #ffffff;

      .unreserved {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 70%;
        height: 100%;
        font-family: 'Lato', sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 19px;

        color: #125d98;
      }
    }
  }
}

.desc {
  display: none;
}

.designation{
  display: none;
  white-space: nowrap;
  font-size: 14px;
  color: #4a5568;
  margin-right: 10px;
}

@media screen and (max-width: 1360px) {
  .designation{
    display: inline-block;
  }

  thead {
    display: none;
  }
  table {
    tbody {
      tr {
        align-items: center;
        height: fit-content;
        padding: 10px 0;
        padding-left: 0px;

        &:hover {
          background-color: #ffffff;
        }

        td {
          display: flex;
          align-items: center;

          .checkbox_form{
            display: flex;
            align-items: center;
          }
        }

        td:has(.designation.name) {
          align-items: start;
        }
      }
    }
  }

  .hotel_table {
    tbody{
      tr {
        td {
          flex: 1 1 330px;
        }
      }
    }
  }
  tr {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-start;
    margin: 0.5em 0;
    padding: 0px 0px;
  }
  td {
    padding: 5px 5px;
    padding: 10px 5px;
    text-align: start;
  }
  td,
  th {
    height: fit-content;
    flex: 1 1 330px;
  }
  td.edit-buttons,
  td.empty {
    flex: 1 0 90%;
    text-align: center;
  }

  .desc {
    display: flex;
    color: #000000;
    margin-right: 7px;
    // padding-bottom: 8px;
  }
  .actions div {
    display: flex;
    gap: 20px;
  }
}

.confirmation_btn{
  padding-bottom: 20px;
}
