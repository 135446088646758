.icon-arow{
    position: fixed;
    bottom: 50px;
    right: 20px;
    transition: 0.4s linear;
    background: #125D98;
    border-radius: 50%;
    padding: 13px 13px 8px 13px;
    color: white;
    font-size: 24px;
    cursor: pointer;
}



@media(max-width:400px){

    .icon-arow{
        padding: 12px 12px 7px 12px;
        font-size: 21px;
    }
}