.log-in__wrapper {
  max-width: 1276px;
  margin: 40px auto;
  display: flex;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.04);
  border-radius: 12px;
}

.wrapper-log__inside{
  padding: 0 0 45px 0;
}

.password__eyes{
  display: flex;
  align-items: center;
  justify-content: end;
}

.password__eyes span{
  position: absolute;
  cursor: pointer;
}

.mecca__image-enter {
  display: flex;
  justify-content: center;
  align-items: center;
}
.error {
  color: red;
  border-color: red;
  font-family: "Lato";
  font-style: normal;
  font-weight: 300;
  font-size: 12px;
  line-height: 165%;
}



.log-in__how {
  text-align: center;
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 33px;
  color: #112f53;margin-top: 20px;
  margin-bottom: 20px;
}

.log-in__users {
  display: flex;
  justify-content: center;
  gap: 24px;
}

.log-user {
  padding: 11px 31px;
  border: 1px solid #125d98;
  border-radius: 8px;
  margin-bottom: 6px;
  cursor: pointer;
  color: #125d98;
}



.log-user.active {
  background-color: #125d98;
  color: white;
}

.log-user.active img {
  filter: invert(100%) sepia(50%) saturate(0%) hue-rotate(70deg) brightness(150%) contrast(105%);
}


.log-user .user-name__text{
  margin-bottom: 5px;
}



.log-user:hover {
  background-color:#125d98;
  color: white;
}

.log-user:hover img {
  filter: invert(100%) sepia(50%) saturate(0%) hue-rotate(70deg) brightness(150%) contrast(105%);
}

.register__inputs {
  margin-top: 56px;
  display: flex;
  justify-content: center;  
  flex-wrap: wrap;
  gap: 40px;
}

.register__inputs .form--item input {
  width: 288px;
  border-bottom: 1px solid #125D98;
}

.register__none {
  display: none;
}

.add__photobtn button {
  padding: 14px 63px;
  background-color: #ffffff;
  color: #125d98;
  border: 1px solid #125d98;
  border-radius: 4px;
}

.add__photobtn button:hover {
  color: white;
  background-color: #125d98;
}

.sendButton {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  gap: 32px;
  align-items: center;
}

.sendButton button:disabled{
  width: 291px;
  background: gainsboro;
  color: red;
  border: 1px solid black;
  color: black;
  opacity: 0.6;
}

.sendButton a{
  text-decoration: underline;
  color: #125d98;
}
  

.sendButton button:enabled {
  padding: 14px 104px;
  background: #125d98;
  border: 1px solid #125d98;
  color: white;
  border-radius: 4px;
}

.log-user h1 {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #112f53;
}

.user_icon {
  font-size: 20px;
  text-align: center;
}




.registration{
  text-decoration: underline;
}


.main__confirmed{
    margin-top: 36px;
    display: flex;
    justify-content: center;
}

.confirmed{
  color: #112F53;
  width: 450px;
  text-align: center;
}


.travel_zam-zam{
  margin-top: 28px;
  display: flex;
  justify-content: center;
  gap: 7px;
}

.travel_zam-zam b{
  text-decoration: underline;
  color: #125d98;
}




@media (max-width: 555px) {
  .log-in__users {
    flex-wrap: wrap;
  }
  .log-user .user-name__text {
    text-align: center;
  }
}

@media (max-width: 585px) {
  .mecca__image-enter img {
    width: 500px;
  }

  .PhoneInputCountry{
    width: 100%;
    max-width: 30px;
  }
}

@media (max-width: 500px) {
  .mecca__image-enter img {
    width: 400px;
  }

  .confirmed{
    width: 350px;
    font-size: 15px;
  }

  .sendButton{
    flex-wrap: wrap;
    gap: 15px;
  }

}

@media (max-width: 400px) {
  .mecca__image-enter img {
    width: 300px;
  }
  .register__inputs .form--item input{
    width: 265px;
  }

  .confirmed{
    width: 270px;
    font-size: 14px;
  }

  .travel_zam-zam{
    font-size: 15px;
  }
}




@media (max-width: 340px) {
  .mecca__image-enter  img {
    width: 300px;
  }
}
