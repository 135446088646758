.instagram {
  &__title {
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 165%;
    display: flex;
    align-items: center;
    color: #000000;
  }

  &__btn a {
    padding: 8px 16px;
    background: #125d98;
    border-radius: 4px;
    font-family: "Lato";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #ffffff;
    width: fit-content;
  }

  &__feed {
    display: grid;
    grid-template-columns: repeat(2, 156px);
    gap: 20px;
    padding: 15px;

    img {
      width: 156px;
      height: 156px;
      display: block;
      object-fit: cover;
      transition: all 100ms ease-out;
    }

    video {
      width: 156px;
      height: 156px;
      display: block;
      transition: all 100ms ease-out;
    }
  }
}
