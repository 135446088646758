.linear {
  width: 100%;
  height: 1px;
  background-color: #474665;
  margin: 20px 0px;
}

.borderBlack {
  border: 2px dashed #125d98;
}

.send_button {
  padding: 0 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background: #125d98;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  border: none;
  cursor: pointer;
}
.send_read {
  padding: 0 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  background: #f7fafc;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  color: #112f53;
  border: none;
  cursor: pointer;
}

button {
  padding: 16px 34px;
  height: 40px;
  background: #125d98;
  border-radius: 4px;
  font-weight: 500;
  font-size: 16px;
  color: #ffffff;
  border: none;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;

  &.add {
    background-color: #fff;
    border: 1px solid #125d98;
    border-radius: 4px;
    height: 35px;
    font-family: "Lato";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;

    color: #125d98;
  }

  &.added {
    background-color: #f1fbf4;
    border: 1px solid #00ba38;
    border-radius: 4px;
    height: 35px;
    font-family: "Lato";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    display: flex;
    align-items: center;
    color: #00ba38;
  }

  &.exit {
    background: #f76659;
    border-radius: 4px;
    @media (max-width: 485px) {
      img {
        display: none;
      }
    }
    @media (max-width: 435px) {
      font-size: 15px;
      padding: 16px 16px;
    }
    @media (max-width: 380px) {
      font-size: 15px;
      padding: 16px 10px;
    }

    @media (max-width: 350px) {
      font-size: 15px;
      padding: 13px 5px;
    }
  }
}

.DateInput_input::placeholder {
  color: #000000;
}

textarea {
  overflow: auto;
  height: 70px;
  border: none;
  border-bottom: 1px solid #474665;
  max-width: 730px;
  padding-bottom: 8px;
  line-height: 19px;
  font-size: 16px;
  font-weight: 400;
  color: hsl(0deg, 0%, 20%);
  background: initial;
  outline: none;
  resize: none;
  -ms-overflow-style: none;
}

.btn_route {
  width: 180px;
  font-size: 12px;
  line-height: 1;
  display: flex;
}
.change_title {
  display: flex;
  gap: 4px;
}

.title {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: #000000;
}

.edit_icon {
  width: 18px;
  height: 18px;
  padding: 5px;
  background: #125d98;
  border-radius: 56px;
  cursor: pointer;
}

// .active-bc {
//   padding-top: 30px;
//   padding-left: 30px;
// }

.edit_hotel{
  padding: 20px;
}

.editing_icon {
  padding: 5px;
  height: 18px;
  font-family: "Roboto";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
  color: #125d98;
}

.ok_icon {
  cursor: pointer;
  width: 18px;
  height: 18px;
  padding: 5px;
  background: #125d98;
  border-radius: 2px;
  font-family: "Lato";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  color: #ffffff;
}

input {
  padding: 8px;
  // width: 120px;
  outline: none;
  height: 20px;
  font-size: 16px;
  font-weight: 400;
  border: none;
  // color: hsl(0, 0%, 20%);
  border-bottom: 1px solid #474665;
  background: initial;
}

input[type="checkbox"]{
  width: 20px;
}


input:focus {
  outline: none;
}
.black_link {
  color: #474665;
}

.back {
  margin-bottom: 30px;
}

a.back {
  width: fit-content;
  align-items: center;
  background: #125d98;
  border: none;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  gap: 20px;
  height: 40px;
  justify-content: center;
  padding: 0px 34px;
}

label {
  font-weight: 400;
  font-size: 18px;
}
.first__trhree-mains {
  display: flex;
  gap: 40px;
}

.description__mains {
  margin-top: 50px;
}

.label__mains {
  margin-bottom: 10px;
}

.first__two-mains {
  display: flex;
  gap: 40px;
  margin-top: 50px;
}

.the__main-title input {
  width: 200px;
}

.the__main-title textarea {
  width: 100%;
  height: 100%;
}

.inputs {
  display: flex;
  padding-top: 20px;
  align-items: center;
  gap: 40px;
}

.profile__about__text {
  .quill {
    .ql-container {
      border: none;
      .ql-editor {
        padding: 0px;
      }
    }
  }
}

.error_text {
  font-family: "Lato", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 165%;
  color: #f76659;
}

.input_100 {
  width: 100px !important;
}

.react-datepicker-wrapper {
  background: rgba(70, 163, 236, 0.1);
  border: 1px solid #125d98;
  border-radius: 6px;
  width: 220px;
  height: 36px;
}

.react-datepicker-wrapper input {
  border: none;
  outline: none;
}

.flex {
  display: flex;
  gap: 32px;
  margin-top: 16px;
  margin-left: 23px;

  @media (max-width: 400px) {
    margin-left: 0;
  }
}

.flex > div {
  width: 100%;
}

.excursion__data {
  table {
    max-width: 688px;
    width: 100%;
  }
}

.tour_places {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 32px;

  &__counts {
    padding-top: 20px;
    display: flex;
    width: 100%;
    justify-content: space-around;
    flex-wrap: wrap;
    gap: 16px;

    &--input {
      width: calc(100% - 30px);
      height: 100%;
      outline: none;
      text-align: center;
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 19px;
      color: #125d98;
    }
    .reserved {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
  &__count {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

button[disabled] {
  background: #e0e0e0;
  cursor: not-allowed;
}
