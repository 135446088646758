.icon-arow{
    position: fixed;
    bottom: 50px;
    right: 50px;
    transition: 0.4s linear;
    background: #125D98;
    border-radius: 50%;
    padding: 15px 20px 17px 15px;
    color: white;
    font-size: 24px;
    cursor: pointer;
}

