.content {
  display: flex;
  justify-content: start;
  padding: 0px 20px;

  &__blocks {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 40px;
  }

  &__block {
    width: 285px;
    height: 352px;
    background: #ffffff;
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.04);
    border-radius: 12px;

    &__top {
      display: flex;
      align-items: center;
      gap: 11px;
      padding: 14px;
    }

    &__title {
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      color: #112f53;
    }

    &__icon {
      margin-top: 8.5px;
      img {
        width: 120px;
        height: 120px;
        border-radius: 100%;
      }
    }

    &__user--name {
      margin-top: 16px;
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 150%;
      color: #112f53;
    }

    &__user--experience {
      margin-top: 4px;
      font-family: "Lato", sans-serif;
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 150%;
      color: #a6a6a6;
    }

    &__btn {
      display: flex;
      justify-content: center;

      button {
        margin-top: 28px;
        padding: 16px 12px;
      }
    }
  }

  &__center {
    text-align: center;
  }
}
