.list_main {
  .list_head {
    padding: 7px 45px 7px 24px;
    width: 541px;
    display: flex;
    align-items: center;
    gap: 80px;
    background: #edf2f7;

    .list_head_text {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 165%;
      color: #4a5568;
    }
  }

  .list_body {
    padding: 16px 45px 15px 24px;
    width: 541px;
    display: flex;
    align-items: center;
    gap: 80px;
    background: rgba(70, 163, 236, 0.2);

    .list_head_text {
      font-family: 'Lato', sans-serif;
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 165%;
      color: #4a5568;
    }
  }
}
.transport {
  padding: 0 22px;
}

.Visa {
  padding: 10px 22px;
  .form--item {
    padding: 10px 0px 0 25px;
  }
  .visa_form {
    padding: 3px 20px 20px 29px;

    .visa_transport {
      padding-top: 8px;

      .main_transport {
        display: flex;
        align-items: center;
        gap: 32px;
      }
    }

    .transport {
      padding-top: 24px;

      label {
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #474665;
      }

      input {
        outline: none;
        width: 100%;
        min-width: 328px;
        max-width: 328px;
        height: 20px;
        margin-right: 32px;
        margin-top: 12px;
        font-size: 16px;
        font-weight: 400;
        border: none;
        border-bottom: 1px solid #474665;
      }
    }

    .price {
      display: flex;
      align-items: center;
      gap: 32px;
      padding-top: 17px;
      padding-bottom: 20px;
      input {
        outline: none;
        max-width: 516px;
        width: 100%;
        padding-top: 12px;
        padding-bottom: 12px;
        background: #edf2f7;
        border-radius: 4px;
        border: none;
        padding-left: 16px;

        &::placeholder {
          font-family: 'Roboto', sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #4a5568;
        }

        &.error {
          border: red solid 1px;
        }
      }

      button {
        font-weight: 400;
      }
      .label_price {
        padding: 12px 34px;
        background: #125d98;
        border-radius: 4px;
        font-weight: 500;
        font-size: 16px;
        color: #ffffff;
        border: none;
        cursor: pointer;

        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
