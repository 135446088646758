.wrapper__container-headerfoot {
    max-width: 1180px;
    margin: 0 auto;
    position: relative;
    top: -90px;
}







.hidden__icon-arrow{
    display: none;  
    cursor: pointer;
}

.wrapper-footer__inside {
    padding: 20px 45px 23px 54px;
    background: #FFFFFF;
    box-shadow: 0px 0px 36.1798px rgba(0, 0, 0, 0.08);
    border-radius: 12px;
    display: flex;
    align-items: center;
    position: relative;
    z-index: 2;
}

.input-in__hiiden label {
    display: none;
}

.wrapper-begin p{
    display: none;
}

.wrapper-begin {
    display: flex;
    align-items: center;
    gap: 12px;

}


.wrapper-begin {
    font-style: normal;
    font-weight: 300;
    font-size: 20px;
    line-height: 24px;
    margin-bottom: 10px;
    color: #000000;
}

.wrapper-footer__search {
    margin: 0 0 0 50px;
    padding: 12px 12px 43px 12px;
    border-radius: 50%;
    display: block;
}

.wrapper-foter__clear{
    margin-top: 15px;
    display: flex;
    gap: 9px;
    cursor: pointer;
}

.wrapper-foter__clear p{
   
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    color: #F76659;

}

.wrapper-footer__about-filters{
    display: flex;
    justify-content: space-between;
}



.wrapper-foot {
    display: flex;
    gap: 30px;
}

.wrapper-date__ajy {
    font-size: 15px;
    border: none;
    font-style: normal;
    font-weight: 300;
    font-size: 16px;
    line-height: 19px;
    text-align: center;

    color: #112F53;
}


.select {
    min-width: 180px;
}

.select_category{
    min-width: 155px;
}


.wrapper-end {
    margin-left: 15px;
}

input.selected{
    min-width: 190px;
}

.selected.active {
    min-width: 200px;
}

.wrapper-date {
    display: flex;
    align-items: center;
}

.wrapper-date p{
    display: flex;
    justify-content: center;
}

.wrapper-footer__about-information{
    margin-right: 35px;
}




.fromSelect {
    border: none;
    width: 125px;
}


.toSelect {
    font-size: 15px;
    border: none;
    width: 125px;
}


@media (max-width:1150px){


    .fromSelect{
        width: 100%;
    }

    .toSelect{
        width: 100%;
    }

    .right-begin__wrapper{
        display: none;
    }

    .wrapper-footer__about-filters{
        display: block;
    }

    .wrapper-footer__inside{
        display: block;
    }

    .wrapper-date{
        display: block;
        width: 100%;
    }

    .wrapper-footer__about-information{
        margin-bottom: 20px;
        display: flex;
        gap: 10px;
    }

    .wrapper-end{
        display: flex;
        margin: 0;
    }
}



@media(max-width:470px){
    .wrapper-foot{
        flex-wrap: wrap;
    }


    .wrapper-foot{
        display: block;
    }

    .wrapper-begin{
        font-size: 18px;
    }

    .wrapper-footer__inside{
        padding: 0;
    }

    .wrapper-footer__about-filters{
        padding: 20px 0 0 40px;
    }


}


@media (max-width:420px){
       .btns_information{
        display: flex;
        flex-direction: column-reverse;
        align-items: flex-end;
    }

    .input-in__hiiden label{
        display: inline;
        border: none;
        color: #ffff;
    }

.input-in__hiiden{
    margin-top: 10px;
}

.input-in__hiiden label{
    cursor: pointer;
}

.wrapper-footer__search img{
    margin-top: 10px;
}

.wrapper-foter__clear{
    margin-right: 10px;
}

    .wrapper-footer__search{
        margin: 10px 0 0 0;
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 0;
        border-bottom-left-radius: 12px;
        border-bottom-right-radius: 12px;
    }
}

@media (max-width:378px){
    .wrapper-footer__about-filters{
        padding: 20px 0 0 20px;
    }
}


@media (max-width:358px){
    .wrapper-footer__about-filters{
        padding: 20px 0 0 15px;
    }

    .wrapper-begin{
        font-size: 16px;
    }
}











 





